import { makeStyles } from '@material-ui/core/styles';

const useEditAccountStyles = makeStyles((theme) => ({
  background: {
    background: 'url("/images/opacity-logo.png") center center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'white'
  },
  mainContainer: {
    padding: '48px 0',
    color: 'black',
    height: '80vh',
    '& h6': {
      fontWeight: 600
    },
    '& a': {
      textDecoration: 'none',
      color: 'orange',
      fontSize: '16px',
      fontWeight: 500
    }
  },
  littleFooter: {
    height: '36px',
    color: 'white',
    fontSize: '10px',
    backgroundColor: 'black',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    paddingTop: '12px',
    paddingLeft: '8px'
  },
  subscription: {
    textAlign: 'center'
  },
  section: {
    paddingBottom: '16px',
    borderBottom: '1px solid orange'
  },
  sectionDetails: {
    borderBottom: '1px solid orange',
    '& h6': {
      paddingTop: '12px',
      lineHeight: '1'
    }
  },
  lastSection: {
    padding: '36px 0 0 0'
  },
  accountTypeContainer: {
    textAlign: 'left',
    width: '100%',
    paddingTop: '12px',
    paddingBottom: '48px'
  },
  accountDetail: {
    color: 'orange',
    fontWeight: 600,
    fontSize: '16px',
    '& a': {
      width: '120px',
      padding: '12px 24px',
      backgroundColor: 'black',
      borderRadius: '5px',
      fontWeight: 600
    }
  },
  alignLeft: {
    textAlign: 'center',
    paddingTop: '60px'
  },
  optionsContainer: {
    marginTop: '-10vh'
  },
  titleContainer: {
    paddingTop: '2rem'
  },
  contentContainer: {
    paddingTop: '2rem'
  },
  [theme.breakpoints.up('sm')]: {
    mainContainer: {
      height: '85vh'
    },
    subscription: {
      textAlign: 'left'
    },
    alignLeft: {
      textAlign: 'left',
      paddingTop: '8px'
    },
    optionsContainer: {
      marginTop: '-20vh'
    }
  },
  [theme.breakpoints.up('md')]: {
    optionsContainer: {
      marginTop: '0'
    },
    titleContainer: {
      paddingTop: '8rem'
    },
    contentContainer: {
      paddingTop: '8rem'
    },
  },
  plansDialog: {
    width: '80vw'
  },
  changeEmailContent: {
    textAlign: 'center'
  },
  fullWidthInput: {
    width: '100%',
    marginBottom: '2rem'
  }
}));

export default useEditAccountStyles;
