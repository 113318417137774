import React from 'react';
import { Box, Button, CircularProgress, Container, Grid, Paper, Typography } from '@material-ui/core';
import useValidateEmailStyles from './ValidateEmail.styles';
import { Trans } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react';
import MailIcon from '@material-ui/icons/Mail';
import useStores from '../../hooks/useStores';
import { observer } from 'mobx-react';
import FullScreenBackground from '../full_screen_background/FullScreenBackground';
import SimpleHeader from '../simple_header/SimpleHeader';
import { CheckCircleOutline } from '@material-ui/icons';

const ValidateEmail = () => {

  const classes = useValidateEmailStyles();
  const { user } = useAuth0();
  const { userStore } = useStores();

  const buttonVerified = (
    <Button variant='contained' color='primary'
            startIcon={<CheckCircleOutline />}
            onClick={() => window.location.reload()}
    >
      <Trans i18nKey='verify_email.check_verified' />
    </Button>
  );

  return (
    <Container className={classes.root}>
      <FullScreenBackground />
      <SimpleHeader />
      <Paper className='content'>
        <Typography variant='h2' className='message'>
          <Trans i18nKey='verify_email.title' />
        </Typography>
        <Typography>
          <Trans i18nKey='verify_email.message' values={user} />
        </Typography>
        <Box textAlign='center' my={4}>
          {userStore.verificationEmailSent ? (
            <>
              <Typography className='email-sent'>
                <Trans i18nKey='verify_email.sent' />
              </Typography>
              <Box mt={2}>
                {buttonVerified}
              </Box>
            </>
          ) : (
            userStore.loading ? (
              <CircularProgress size={50} />
            ) : (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Button variant='contained' color='secondary' className='send-button'
                      startIcon={<MailIcon />}
                      onClick={() => userStore.sendEmailVerification()}
                    >
                      <Trans i18nKey='verify_email.send' />
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    {buttonVerified}
                  </Grid>
                </Grid>
              </>
            )
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default observer(ValidateEmail);
