import { AppBar, Fade, Grid, IconButton, InputAdornment, TextField, Toolbar, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getImage } from '../../helpers/AppHelpers';
import useStores from '../../hooks/useStores';
import Loading from '../loading/Loading';
import { VideoCard } from '../video_card/VideoCard';
import { useVideoSearchStyles } from './VideoSearchDialog.styles';

const Transition = (props: TransitionProps & { children?: React.ReactElement }, ref: React.Ref<unknown>) => {
  return <Fade timeout={50} ref={ref} {...props} />;
};
const FadeTransition = React.forwardRef(Transition);

const Component: React.FC = () => {
  const classes = useVideoSearchStyles();
  const { videoStore } = useStores();
  const showMe = videoStore.isSearchVisible();
  const searching = videoStore.isSearching();
  const searchResults = videoStore.getSearchResults();
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');


  useEffect(() => {
    setSearchTerm(videoStore.searchTerm || '');
  }, [videoStore.searchTerm]);

  const search = (): void => {
    videoStore.search(searchTerm);
  };

  const close = (): void => {
    videoStore.closeSearch();
  };

  return (
    <Dialog fullScreen open={showMe} TransitionComponent={FadeTransition} aria-labelledby="video-search-dialog">
      <AppBar position="sticky" color="default">
        <Toolbar>
          <img className={classes.logo} src={getImage('cropped-isotipo-careflix-180x180.png')} alt="logo"/>
          <TextField
            className={classes.textField}
            fullWidth
            placeholder={t('global.search')}
            value={searchTerm}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={classes.icon} color="disabled"/>
                </InputAdornment>
              )
            }}
            onChange={(ev) => setSearchTerm(ev.target.value)}
            onKeyPress={(ev) => ev.key === 'Enter' && !!searchTerm?.length && search()}
          />
          <IconButton
            className={classes.iconButton}
            edge="end"
            color="inherit"
            onClick={search}
            aria-label="search"
            disabled={!searchTerm?.length}
          >
            <SearchIcon className={classes.icon}/>
          </IconButton>
          <IconButton className={classes.iconButton} edge="end" color="inherit" onClick={close} aria-label="close">
            <CloseIcon className={classes.icon}/>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Grid container className={classes.resultsGrid}>
        {!!searchResults &&
        !!searchResults.length &&
        searchResults.map((video) => (
          <Grid key={video.id} item className={classes.resultsItem} xs={12} sm={6} md={4} lg={3}>
              <VideoCard video={video} disableDetailsTooltip={true}/>
            </Grid>
        ))}
        {!!searchResults && !searchResults?.length && (
          <Grid item className={classes.resultsItem} sm={12}>
            <Typography variant="body1">
              <Trans i18nKey="global.noSearchResults"/>
            </Typography>
          </Grid>
        )}
        {searching && <Loading/>}
      </Grid>
    </Dialog>
  );
};

export const VideoSearchDialog = observer(Component);
