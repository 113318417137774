import { makeStyles } from '@material-ui/core/styles';

const useProfileHeaderStyles = makeStyles((theme) => ({
  root: {
    '& > .logo img': {
      margin: theme.spacing(2),
      height: theme.spacing(14),
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(1),
        height: theme.spacing(9)
      }
    },
    '& > .title': {
      textAlign: 'center',
      fontWeight: 500,
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(2),
      }
    }
  }
}));

export default useProfileHeaderStyles;
