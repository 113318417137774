import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export interface LiveEventStyleProps {
  image: string
}

const useLiveEventDialogStyles = makeStyles<Theme, LiveEventStyleProps>((theme) => ({
  root: {
    '& .MuiPaper-root': {
      boxShadow: '1px 1px 20px 2px rgba(255, 255, 255, 0.2)'
    },
    '& .MuiDialogContent-root': {
      minHeight: '50vh',
      background: props => `linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${props.image}) center/cover no-repeat`,
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1)
      }
    },
    '& *': {
      textShadow: '1px 1px 1px #444444'
    },
    '& .nowrap': {
      whiteSpace: 'nowrap'
    },
    '& .close': {
      marginLeft: theme.spacing(1),
      padding: theme.spacing(1),
      height: 40,
      '&:not(:hover)': {
        backgroundColor: 'rgba(0, 0, 0, .5)'
      }
    },
    '& .title': {
      fontWeight: 600,
      flexGrow: 1,
      lineHeight: 'normal',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '& .description': {
      marginTop: theme.spacing(2)
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiDialog-paper': {
        width: '100vw',
        margin: theme.spacing(1.5)
      }
    }
  }
}));

export default useLiveEventDialogStyles;
