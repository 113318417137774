import { makeStyles } from '@material-ui/core/styles';

const useFooterStyles = makeStyles((theme) => ({
    fullWidth: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: '20%',
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '5%',
            textAlign: 'left'
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: '18%'
        },
        marginTop: '24px',
        '& .social-icon': {
            marginRight: '16px'
        }
    },
    linksFullWidth: {
        paddingLeft: '20%',
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '5%',
            textAlign: 'left'
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: '18%'
        },
        paddingRight: '20%',
        marginTop: '24px',
        marginBottom: '5%',
        '& a': {
            textDecoration: 'none',
            color: 'white'
        },
        '& div': {
            margin: '8px 0',
            fontFamily: theme.typography.fontFamily,
            fontSize: '16px',
            fontWeight: theme.typography.fontWeightMedium
        }
    }
}));

export default useFooterStyles;