import React from 'react';
import { Box, Container } from '@material-ui/core';
import { MagnoliaImage } from '../image/Image';
import { getMagnoliaUrlImage } from '../../helpers/AppHelpers';
import RichText from '../rich_text/RichText';

interface Props {
  content: string,
  image: MagnoliaImage
}

const MainCarouselItem = (props: Props) => {

  const { content, image } = props;

  return (
    <Box className="banner" style={{ backgroundImage: getMagnoliaUrlImage(image['@link']) }}>
      <Container>
        <RichText text={content}/>
      </Container>
    </Box>
  );
};

export default MainCarouselItem;
