import { makeAutoObservable, runInAction } from 'mobx';
import { Video } from '../domain/Video';
import { get } from '../services/http.service';

interface VideosMap {
  [key: string]: Video[];
}

enum SearchUrl {
  Terms = '/vimeo/videos/search?searchTerm=',
  VideoProspects = 'vimeo/videos?tags=videoprospecto,'
}

class VideoStore {
  private tagVideos: VideosMap = {};
  private active: Video = null;
  private playing = false;
  private searchUrl: SearchUrl = null;
  private searching = false;
  private searchResults: Video[] = null;
  private loadingTagMap: { [key: string]: boolean } = {};
  private _searchTerm: string;
  private topRatedVideos: Video[] = null;
  private topRatedLoading: boolean;
  private isLiveEvent: boolean;

  constructor() {
    makeAutoObservable(this);
  }

  setTagsVideos(tags: string, videos: Video[]) {
    this.tagVideos[tags] = videos;
  }

  getTagsVideos(folderId: string): Video[] {
    return this.tagVideos[folderId] ?? [];
  }

  getIsLiveEvent(): boolean {
    return this.isLiveEvent;
  }

  setIsLiveEvent(isLive: boolean) {
    this.isLiveEvent = isLive;
  }

  getActive(): Video {
    return this.active;
  }

  setActive(v: Video): void {
    this.active = v;
  }

  setPlaying(v: boolean): void {
    this.playing = v;
  }

  setTopRatedVideos(videos: Video[]) {
    this.topRatedVideos = videos;
  }

  getTopRatedVideos(): Video[] {
    return this.topRatedVideos;
  }

  setTopRatedLoading(loading: boolean) {
    this.topRatedLoading = loading;
  }

  getTopRatedLoading(): boolean {
    return this.topRatedLoading;
  }

  isDetailsVisible(): boolean {
    return !!this.active && !this.playing && !this.isLiveEvent;
  }

  isPlayerVisible(): boolean {
    return !!this.active && this.playing;
  }

  isSearchVisible(): boolean {
    return !!this.searchUrl;
  }

  openSearch() {
    this.setSearchUrl(SearchUrl.Terms);
  }

  openVideoProspectSearch(tag?: string) {
    this.setSearchUrl(SearchUrl.VideoProspects);
    if (!!tag) {
      this.search(tag).then();
    }
  }

  closeSearch() {
    this.setSearchUrl();
  }

  setSearchUrl(url?: SearchUrl): void {
    runInAction(() => {
      this.searchUrl = url;
      this.searchResults = null;
      this._searchTerm = null;
    });
  }

  isSearching(): boolean {
    return this.searching;
  }

  isTagLoading(tags: string) {
    return this.loadingTagMap[tags];
  }

  getSearchResults(): Video[] {
    return this.searchResults;
  }

  get searchTerm() {
    return this._searchTerm;
  }

  private setSearching(value: boolean) {
    this.searching = value;
  }

  private setSearchResult(data: Video[]) {
    this.searchResults = data;
  }

  private setSearchTerm(term: string) {
    this._searchTerm = term;
  }

  private setLoadingTag(tags: string, value: boolean) {
    this.loadingTagMap[tags] = value;
  }

  async loadVideosByTags(tags: string) {
    try {
      this.setLoadingTag(tags, true);
      const { data } = await get<Video[]>(`/vimeo/videos?tags=${tags}`);
      this.setTagsVideos(tags, data);
    } finally {
      this.setLoadingTag(tags, false);
    }
  }

  async search(term: string) {
    try {
      this.setSearchTerm(term);
      this.setSearching(true);
      this.setSearchResult(null);
      const { data } = await get<Video[]>(this.searchUrl + term);
      this.setSearchResult(data);
    } finally {
      this.setSearching(false);
    }
  }

  async loadTopRating() {
    try {
      this.setTopRatedLoading(true);
      const { data } = await get<Video[]>('/vimeo/videos/top-rating/5');
      this.setTopRatedVideos(data);
    } finally {
      this.setTopRatedLoading(false);
    }
  }
}

export default VideoStore;
