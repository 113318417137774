import { EditorContextHelper } from '@magnolia/react-editor';
import { MGNL_API_PAGES, MGNL_APP_BASE, MGNL_BASE_AUTHOR, MGNL_BASE_PUBLIC, MGNL_HOST, PUBLIC_URL } from '../constants';
import { first } from 'lodash';
import rootStore from '../stores/RootStore';

export function inDevelopment() {
  return process.env.NODE_ENV === 'development';
}

export function inEditor() {
  return EditorContextHelper.inEditor() || EditorContextHelper.inEditorPreview();
}

export function inEditorOrAuthorMode() {
  return inEditor() || window.location.href.indexOf(MGNL_BASE_AUTHOR) > 0;
}

export function getAPIBase(): string {
  const path = (inDevelopment() || inEditorOrAuthorMode()) ?
    MGNL_BASE_AUTHOR :
    MGNL_BASE_PUBLIC;
  return MGNL_HOST + path;
}

export function getDamImage(id: string): string {
  return `${getAPIBase()}/dam/jcr:${id}`;
}

export function removeCurrentLanguage(text: string) {
  const currentLanguage = getCurrentLanguage();
  const value = text.replace(new RegExp(`/${currentLanguage}($|/)`), '/');
  return removeLastSlash(value);
}

export function getProfileLang(): string {
  return rootStore.profileStore?.selectedProfile?.lang;
}

export function getCurrentLanguage(): string {
  const profileLang = getProfileLang();
  return profileLang ?? navigator.language ?? first(navigator.languages);
}

export function getRouterBasename(): string {
  const nodeName = MGNL_APP_BASE ?? '';
  const pathname = window.location.pathname;
  return pathname.indexOf(nodeName) > -1 ?
    (pathname.replace(new RegExp(nodeName + '.*'), '') + nodeName)
    : '/';
}

export const removeLastSlash = (text: string) => {
  return text?.endsWith('/') ? text.slice(0, -1) : text;
};

export function getCallbackUri() {
  const { origin, pathname } = window.location;
  const publicPath = pathname.includes(MGNL_BASE_PUBLIC) ? MGNL_BASE_PUBLIC : '';
  const path = pathname.includes(MGNL_APP_BASE) ? MGNL_APP_BASE : '';
  return origin + publicPath + path;
}

export const getImage = (image: string) =>
  `${PUBLIC_URL}/images/${image}`;

export const getMagnoliaImage = (image: string) =>
  MGNL_HOST + image;

export const getPDFUrl = (item: string) =>
  MGNL_HOST + item;

export const getMagnoliaUrlImage = (image: string) =>
  `url(${getMagnoliaImage(image)})`;

export const getUrImage = (image: string) =>
  `url(${getImage(image)})`;

export const getAPIPage = (page: string) => {
  const apiBase = getAPIBase();
  return apiBase + MGNL_API_PAGES + page;
}

export const getCarouselResponsive = () => {
  return {
    heavyDesktop: {
      breakpoint: { max: 9999, min: 1600 },
      items: 5
    },
    largeDesktop: {
      breakpoint: { max: 1600, min: 960 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 960, min: 600 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 600, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
}

export const getPublicMainPageURL = () => {
  return MGNL_BASE_PUBLIC + MGNL_APP_BASE;
}
