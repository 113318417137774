import { makeStyles, Theme } from '@material-ui/core';

export interface VideoDetailsMiniTooltipStylesProps {
  thumbnailUrl: string;
}

export const useVideoDetailsMiniTooltipStyles = makeStyles<Theme, VideoDetailsMiniTooltipStylesProps>((theme) => ({
  thumbnail: {
    backgroundImage: (props) => `url(${props.thumbnailUrl})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    minHeight: 240,
    marginBottom: theme.spacing(2)
  },
  buttonsAndRating: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(2)
    }
  },
  title: {
    padding: theme.spacing(2)
  },
  tooltipContainer: {
    maxWidth: '420px',
    minWidth: '360px',
    padding: 0,
    backgroundColor: theme.palette.grey[800]
  }
}));
