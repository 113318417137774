import { useContext } from 'react';
import { DialogsContext } from '../providers/DialogProvider';

const useDialogs = () => {

  const { state, setState } = useContext(DialogsContext);

  const showSnackbar = (message: string) => showSnackbarMessage(message, false);

  const showSnackbarError = (message: string) => showSnackbarMessage(message, true);

  const showSnackbarMessage = (message: string, error?: boolean) => {
    setState({
      ...state,
      snackbarMessage: message,
      snackbarError: error
    });
  };

  const showConfirm = (confirmMessage: string, onCloseConfirm: (resut: boolean) => void) => {
    setState({
      ...state,
      confirmMessage,
      onCloseConfirm
    });
  }

  return {
    showSnackbar,
    showSnackbarError,
    showConfirm
  }
};

export default useDialogs;
