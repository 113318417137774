import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { get, post, remove } from '../services/http.service';
import { Profile } from '../domain/Profile';
import { DamImage } from '../domain/DamImage';
import { getProfileImages } from '../services/magnolia.http.service';
import { getFromLocalStorage, removeLocalStorageItem, setToLocalStorage } from '../utils/AppUtils';

enum StorageKey {
  SelectedProfile = 'SelectedProfile',
  Profiles = 'Profiles'
}

class ProfileStore {

  private _loading = false;
  private _selectedProfile?: Profile;
  private _editProfile?: Profile;
  private _profiles: Profile[] = [];
  private _images: DamImage[] = []

  constructor() {
    makeAutoObservable(this);
    this._selectedProfile = getFromLocalStorage(StorageKey.SelectedProfile);
    this._profiles = getFromLocalStorage(StorageKey.Profiles, []);
  }

  get profiles() {
    return this._profiles;
  }

  get loading() {
    return this._loading;
  }

  get editProfile() {
    return this._editProfile;
  }

  get profileImages() {
    return this._images;
  }

  get selectedProfile() {
    return this._selectedProfile;
  }

  get isProfileSelectedOrEditing() {
    return !!this.selectedProfile || !!this.editProfile;
  }

  setEditProfile(profile?: Profile) {
    this._editProfile = toJS(profile) || {} as Profile;
  }

  setSelectedProfile(profile: Profile) {
    this._selectedProfile = toJS(profile);
    setToLocalStorage(StorageKey.SelectedProfile, this._selectedProfile);
  }

  resetProfiles() {
    this._profiles = [];
    this._selectedProfile = null;
    removeLocalStorageItem(StorageKey.SelectedProfile);
    removeLocalStorageItem(StorageKey.Profiles);
  }

  async loadProfiles() {
    try {
      this._loading = true;
      const { data } = await get<Profile[]>('/users/profiles');
      runInAction(() => {
        this._profiles = data;
        this._loading = false;
        this.storeProfiles();
      });
    } catch (e) {
      this._loading = false;
    }
  }

  async saveProfile(profile: Profile) {
    try {
      this._loading = true;
      const { data } = await post<Profile, Profile>('/users/profiles', profile);
      runInAction(() => {
        const modifiedProfile = this._profiles.find(it => it.id === data.id);
        if (!!modifiedProfile) {
          Object.assign(modifiedProfile, data);
        } else {
          this._profiles.push(data);
        }
        this.storeProfiles();
        this._loading = false;
      });
    } catch (e) {
      this._loading = false;
    }
  }

  async loadProfileImages() {
    try {
      this._loading = true;
      const { data } = await getProfileImages();
      runInAction(() => {
        this._images = data;
        this._loading = false;
      });
    } catch (e) {
      this._loading = false;
    }
  }

  async removeProfile(profileId: number) {
    await remove('/user-profile/' + profileId);
  }

  private storeProfiles() {
    setToLocalStorage(StorageKey.Profiles, this._profiles);
  }

}

export default ProfileStore;
