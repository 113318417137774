import React, { useState } from 'react';
import { AppBar, Box, Drawer, Grid, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { getImage } from '../../helpers/AppHelpers';
import useHeaderStyles from '../header/Header.styles';
import useAccountHeaderStyles from './AccountHeader.styles';
import { Trans } from 'react-i18next';
import { UserVideosDialog } from '../user_videos_dialog/UserVideosDialog';
import HeaderOptions from '../header/options/HeaderOptions';
import { VideoDetailsDialog } from '../video_details_dialog/VideoDetailsDialog';
import { VideoPlayer } from '../video_player/VideoPlayer';
import { VideoSearchDialog } from '../video_search_dialog/VideoSearchDialog';
import MenuIcon from '@material-ui/icons/Menu';
import HeaderLinks from '../header/links/HeaderLinks';

const AccountHeader = () => {

  const classes = useHeaderStyles();
  const classesMyAccount = useAccountHeaderStyles();
  const [showMenu, setShowMenu] = useState(false);
  const theme = useTheme();
  const isDownXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      <AppBar position="sticky" className={classes.root}>
        <Toolbar style={{position: 'relative'}}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item xs={2}>
              <Link to="/" className="logo">
                <img src={getImage('logo.png')} alt="logo"/>
              </Link>
            </Grid>
            <Grid item xs={8} className={classesMyAccount.myAccountTitle}>
              <Typography variant="h3">
                <Trans i18nKey="account_header.title" />
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {isDownXs ? (
                <>
                  <IconButton onClick={() => setShowMenu(true)}>
                    <MenuIcon/>
                  </IconButton>
                  <Drawer anchor="right" open={showMenu} onClose={() => setShowMenu(false)}>
                    <Box className={classes.menu}>
                      <HeaderOptions/>
                      <HeaderLinks/>
                    </Box>
                  </Drawer>
                </>
              ) : (
                <>
                  <HeaderOptions/>
                </>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <UserVideosDialog />
      <VideoDetailsDialog />
      <VideoPlayer />
      <VideoSearchDialog />
    </>
  );

}

export default AccountHeader;
