import React, { useEffect, useState } from 'react';
import Carousel, { ResponsiveType } from 'react-multi-carousel';
import useStores from '../../hooks/useStores';
import { VideoCard } from '../video_card/VideoCard';
import { Trans } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { getCarouselResponsive } from '../../helpers/AppHelpers';
import Loading from '../loading/Loading';
import useTagsVideosCarousel from '../tags_videos_carousel/TagsVideosCarousel.styles';
import { observer } from 'mobx-react';

interface Props {
  responsive?: ResponsiveType;
}

const TopRatingVideos = (props: Props) => {
  const classes = useTagsVideosCarousel();
  const { videoStore } = useStores();
  const [loading, setLoading] = useState(true);
  const videos = videoStore.getTopRatedVideos();
  const responsive = props.responsive ?? getCarouselResponsive();

  useEffect(() => {
    videoStore.loadTopRating().then(() => {
      setLoading(videoStore.getTopRatedLoading());
    });
  }, [videoStore]);

  return (
    <Box className={classes.root}>
      {loading && <Loading />}
      <Typography variant="h4" color="primary" className={classes.title}>
        <Trans i18nKey="top-rating.title" />
      </Typography>

      {!loading && videos?.length > 0 ? (
        <Carousel arrows={true} infinite={true} responsive={responsive}>
          {videos?.map((video) => (
            <VideoCard key={video.id} video={video} />
          ))}
        </Carousel>
      ) : (
        <Box textAlign="center" mt={2}>
          <Typography variant="h4">
            <Trans i18nKey="global.no_videos_found" />
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default observer(TopRatingVideos);
