import React from 'react';
import { EditableArea } from '@magnolia/react-editor';
import useFooterStyles from '../Footer.styles';
import clsx from 'clsx';

interface Props {
  footerlinkitem: object,
  footerpdfitem: object,
  metadata: object
}

const FooterLinksArea = (props: Props) => {

  const { footerlinkitem, footerpdfitem, metadata } = props;
  const classes = useFooterStyles();

  return (
    <>
      {footerlinkitem &&
      <EditableArea
        content={footerlinkitem}
        parentTemplateId={metadata['mgnl:template']}
        elementType='div'
        className={clsx(classes.linksFullWidth, 'MuiGrid-root MuiGrid-container')}
      />
      }
      {footerpdfitem &&
      <EditableArea
        content={footerpdfitem}
        parentTemplateId={metadata['mgnl:template']}
        elementType='div'
        className={clsx(classes.linksFullWidth, 'MuiGrid-root MuiGrid-container')}
      />
      }
    </>
  );

};

export default FooterLinksArea;
