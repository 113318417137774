import { makeStyles } from '@material-ui/core/styles';

const useProfileSelectorStyles = makeStyles((theme) => ({
  root: {
    '& > .welcome': {
      marginTop: theme.spacing(8),
      textAlign: 'center',
      padding: theme.spacing(2)
    }
  },
  profiles: {
    marginTop: theme.spacing(8),
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& .icon': {
        border: '5px solid white',
        borderRadius: 20,
        height: 200,
        width: 200,
        display: 'flex',
        justifyContent: 'center',
        '&.selected': {
          borderColor: theme.palette.secondary.main
        },
        '&:hover': {
          borderColor: theme.palette.primary.main
        }
      },
      '& .name': {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
        lineHeight: '5rem'
      }
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  addProfile: {
    cursor: 'pointer',
    '& .icon': {
      position: 'relative',
      padding: theme.spacing(2, 3),
      '& .MuiSvgIcon-root': {
        fontSize: 160
      },
      '& .add-button': {
        position: 'absolute',
        bottom: -10,
        right: -34,
        borderRadius: 40,
        maxWidth: 50,
        height: 62,
        '& .MuiSvgIcon-root': {
          fontSize: 50
        }
      }
    }
  },
  editButton: {
    marginLeft: '8px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  }
}));

export default useProfileSelectorStyles;
