import { makeStyles } from '@material-ui/core/styles';

const useOnboardingBannerStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '50vh',
    overflowX: 'hidden',
    padding: theme.spacing(8, 1),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      '&.left-image': {
        flexDirection: 'row-reverse',
        '& img': {
          left: 0,
          right: 'inherit'
        }
      }
    },
    '& p': {
      margin: 0
    },
    '& .text-box': {
      flexGrow: 1,
      textAlign: 'center'
    },
    '& .title': {
      fontWeight: 600,
      padding: theme.spacing(4),
      margin: theme.spacing(4, 0)
    },
    '& .subtitle-box': {
      padding: theme.spacing(6, 4),
      position: 'relative',
      '& .background': {
        zIndex: -1,
        top: 0,
        left: 0,
        position: 'absolute',
        marginLeft: '-100vw',
        width: '200vw',
        height: '100%',
        backgroundColor: theme.palette.background.paper
      }
    },
    '& img': {
      padding: theme.spacing(0, 4),
      right: 0,
      bottom: 0,
      maxWidth: '100%',
      maxHeight: 400,
      [theme.breakpoints.up('md')]: {
        maxHeight: '70vh',
        maxWidth: '50vw'
      }
    }
  }
}));

export default useOnboardingBannerStyles;
