import { makeStyles } from '@material-ui/core/styles';

const useMainCarouselStyles = makeStyles((theme) => ({
  root: {
    '& .banner': {
      display: 'flex',
      height: '60vh',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      alignItems: 'center',
      minHeight: 500,
      [theme.breakpoints.down('xs')]: {
        height: '50vh',
        minHeight: 250
      },
      '& .rich-text': {
        width: '100%',
        fontWeight: 600,
        textShadow: '1px 1px 1px black',
        [theme.breakpoints.down('xs')]: {
          zoom: 0.60
        }
      }
    },
    '& .react-multiple-carousel__arrow': {
      display: 'none'
    },
    '&:hover .react-multiple-carousel__arrow': {
      display: 'block'
    }
  }
}));

export default useMainCarouselStyles;
