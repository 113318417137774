import moment from 'moment-timezone';
import { Video } from '../domain/Video';

export const formatVideoDuration = (video: Video): number => {
  return Math.ceil((video.duration ?? 0) / 60);
};

export const formatDate = (value: string): string => {
  const date = moment(value);
  return date.format('DD MMMM');
};

export const formatTime = (value: string): string => {
  const date = moment(value);
  return date.format('HH:mm');
};

export const getFromLocalStorage = (key: string, defaultValue?: any): any => {
  try {
    return JSON.parse(localStorage.getItem(key)) ?? defaultValue;
  } catch (e) {
    return defaultValue;
  }
};

export const setToLocalStorage = (key: string, object: any) => {
  return localStorage.setItem(key, JSON.stringify(object));
};

export const removeLocalStorageItem = (key: string) => {
  localStorage.removeItem(key);
};

export const clearLocalStorage = () => localStorage.clear();
