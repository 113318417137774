export enum ProfileField {
  id = 'id',
  imageId = 'imageId',
  name = 'name',
  lang = 'lang',
  ageConfig = 'ageConfig',
  interests = 'interests'
}

export enum ProfileAgeConfig {
  ALL = 'ALL',
  KIDS = 'KIDS',
  TEENS = 'TEENS'
}

export interface Profile {
  [ProfileField.id]: number;
  [ProfileField.name]: string;
  [ProfileField.imageId]: string;
  [ProfileField.lang]: string;
  [ProfileField.ageConfig]: string;
  [ProfileField.interests]: string[]
}
