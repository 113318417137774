import React from 'react';
import { Box, Typography } from '@material-ui/core';
import RichText from '../rich_text/RichText';
import clsx from 'clsx';
import useOnboardingBannerStyles from './OnboardingBanner.styles';
import Image, { MagnoliaImage } from '../image/Image';

interface Props {
  title: string;
  subtitle: string;
  image: MagnoliaImage;
  imageOnLeft: string;
}

const OnboardingBanner = (props: Props) => {

  const { title, subtitle, image, imageOnLeft } = props;
  const classes = useOnboardingBannerStyles();

  return (
    <Box className={ clsx(classes.root, 'onboarding-card', { 'left-image': JSON.parse(imageOnLeft) }) }>
      <Box className="text-box">
        <Typography variant="h2" className="title">
          <RichText text={ title }/>
        </Typography>
        <Box className="subtitle-box">
          <Box className="background"/>
          <Typography variant="h4">
            <RichText text={ subtitle }/>
          </Typography>
        </Box>
      </Box>
      <Box className="image">
        { image && <Image image={ image } delay={0}/> }
      </Box>
    </Box>
  );
};

export default OnboardingBanner;
