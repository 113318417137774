import { makeStyles } from '@material-ui/core/styles';

const useHeaderStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    '& .MuiToolbar-root': {
      height: 100
    },
    '& .logo':{
      minWith: 300,
      '& img': {
        marginRight: theme.spacing(4),
        maxHeight: theme.spacing(8),
        [theme.breakpoints.down('xs')]: {
          maxHeight: theme.spacing(5)
        }
      }
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiToolbar-root': {
        height: 80,
        padding: theme.spacing(0, 1)
      },
      '& .logo': {
        flexGrow: 1
      }
    }
  },
  menu: {
    width: '85vw',
    padding: theme.spacing(2),
    '& .links': {
      marginTop: theme.spacing(3),
      flexDirection: 'column',
      '& a': {
        textAlign: 'right',
        padding: 0,
        fontSize: 22,
        marginTop: theme.spacing(2)
      }
    }
  }
}));

export default useHeaderStyles;
