import { makeStyles } from '@material-ui/core/styles';
import blueGray from '@material-ui/core/colors/blueGrey';
import yellow from '@material-ui/core/colors/yellow';

const useChoosePlanStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
    color: 'black',
    minHeight: '100vh',
    '& .title': {
      fontWeight: 500
    },
    '& .subtitle': {
      paddingTop: theme.spacing(1)
    },
    '& .content': {
      textAlign: 'center',
      padding: theme.spacing(5, 0),
      fontWeight: 600,
      '& .box': {
        background: blueGray[900],
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },
      '& .names': {
        marginBottom: theme.spacing(1.5),
        '& .item': {
          fontSize: '1.4rem',
          [theme.breakpoints.down('xs')]: {
            fontSize: '0.8rem',
          },
          '&:nth-child(n+1)': {
            color: yellow.A700
          },
          '&:nth-child(2n+1)': {
            color: theme.palette.secondary.main
          },
          '&:nth-child(3n+1)': {
            color: theme.palette.primary.main
          },
          '& .box': {
            position: 'relative',
            padding: theme.spacing(1),
            borderRadius: 10,
            overflow: 'hidden',
            background: theme.palette.background.default,
            flexDirection: 'row',
            '& .MuiSvgIcon-root': {
              fontSize: '2rem',
              marginLeft: theme.spacing(1),
              [theme.breakpoints.down('xs')]: {
                fontSize: '1rem'
              }
            }
          }
        }
      },
      '& .MuiGrid-item.label': {
        [theme.breakpoints.down('sm')]: {
          display: 'none'
        }
      },
      '& .values': {
        width: '100%',
        marginBottom: theme.spacing(4),
        '& .MuiGrid-root:first-child .box': {
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10
        },
        '& .MuiGrid-item': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingBottom: 1,
          '& .box': {
            color: 'white',
            fontSize: '1.4rem',
            padding: theme.spacing(1),
            '& .MuiSvgIcon-root': {
              fontSize: '2rem'
            },
            '& .label': {
              display: 'none',
              [theme.breakpoints.down('sm')]: {
                fontSize: '0.7rem',
                display: 'block'
              }
            }
          },
          '&.button-box': {
            '& .box': {
              padding: theme.spacing(2),
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
            },
            '& .MuiButton-root': {
              color: 'black',
              maxWidth: 120,
              [theme.breakpoints.down('sm')]: {
                fontSize: '0.8rem'
              }
            },
            '&:nth-child(n+1) .MuiButtonBase-root': {
              backgroundColor: yellow.A700
            },
            '&:nth-child(2n+1) .MuiButtonBase-root': {
              backgroundColor: theme.palette.secondary.main
            },
            '&:nth-child(3n+1) .MuiButtonBase-root': {
              backgroundColor: theme.palette.primary.main
            }
          }
        }
      },
      '& .next-button.Mui-disabled': {
        backgroundColor: blueGray[100],
        color: blueGray[200]
      }
    },
    '& .MuiGrid-root': {
      padding: theme.spacing(0, 0.2),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(0, 0.5),
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0, 1),
      }
    }
  }
}));

export default useChoosePlanStyles;
