import React, { ForwardedRef, PropsWithChildren } from 'react';
import useMainCarouselStyles from './MainCarousel.styles';
import { EditableArea, EditorContextHelper } from '@magnolia/react-editor';
import Carousel from 'react-multi-carousel';

interface Props {
  items: any,
  metadata: any
}

const MainCarousel = (props: Props) => {

  const { items, metadata } = props;
  const classes = useMainCarouselStyles();

  const MainCarouselRoot = React.forwardRef((props: PropsWithChildren<{ className }>, ref: ForwardedRef<any>) => {

    const { className, children } = props;

    return (
      <div ref={ref}>
        <Carousel className={className}
          arrows={true}
          autoPlay={true}
          infinite={true}
          autoPlaySpeed={7500}
          transitionDuration={500}
          responsive={{
            all: {
              breakpoint: { max: 99999, min: 0 },
              items: 1
            }
          }}
        >
          {children}
        </Carousel>
      </div>
    );
  });

  MainCarouselRoot.displayName = 'MainCarouselRoot';

  return (
    <div>
      {items && <EditableArea
        elementType={EditorContextHelper.inEditor() ? 'div' : MainCarouselRoot}
        className={classes.root}
        content={items}
        parentTemplateId={metadata['mgnl:template']}/>
      }
    </div>
  );
};

export default MainCarousel;
