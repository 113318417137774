import React, { useContext } from 'react';
import RichText from '../rich_text/RichText';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { OnboardingFaqsContext } from '../onboarding_faqs/OnboardingFaqs';

interface Props {
  question: string,
  answer: string,
  metadata: any,
}

const OnboardingFaqItem = (props: Props) => {

  const { expanded, setExpanded } = useContext(OnboardingFaqsContext);
  const { question, answer, metadata } = props;
  const id = metadata['@id'];

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded ? id : null);
  };

  return (
    <Accordion expanded={expanded === id} onChange={handleChange}>
      <AccordionSummary expandIcon={expanded === id ? <RemoveIcon/> : <AddIcon/>}>
        <PlayArrowIcon color="primary" className="arrow"/>
        <Typography variant="h5">{question}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="div" variant="h6">
          <RichText text={answer}/>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default OnboardingFaqItem;
