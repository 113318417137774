import { makeStyles } from '@material-ui/core/styles';

const useSimpleHeaderStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& img': {
      maxHeight: 80,
      maxWidth: '50vw'
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiButtonBase-root': {
        minWidth: 40,
        '& .MuiButton-startIcon': {
          marginRight: -4
        },
        '& .label': {
          display: 'none'
        }
      }
    }
  }
}));

export default useSimpleHeaderStyles;
