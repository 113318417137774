import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export interface LiveEventStyleProps {
  image: string
}

const useLiveEventStyles = makeStyles<Theme, LiveEventStyleProps>((theme) => ({
  root: {
    '&:hover': {
      cursor: 'pointer'
    },
    '& .title': {
      backgroundColor: theme.palette.primary.main,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      color: 'white',
      textAlign: 'center',
      padding: theme.spacing(1),
      '& *': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontWeight: 600
      }
    },
    '& .content': {
      display: 'flex',
      overflow: 'hidden',
      padding: theme.spacing(1),
      '& .image': {
        position: 'relative',
        height: 200,
        minWidth: '45%',
        borderRadius: 5,
        borderBottomLeftRadius: 0,
        marginRight: theme.spacing(1),
        backgroundImage: props => `url(${props.image})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        '& .date': {
          position: 'absolute',
          backgroundColor: theme.palette.primary.main,
          padding: theme.spacing(0.5, 1),
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          bottom: 0
        }
      }
    },
    '& *': {
      lineHeight: 'normal'

    }
  }
}));

export default useLiveEventStyles;
