import { Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { Video } from '../../domain/Video';
import { useVideoCardStyles } from './VideoCard.styles';
import { VideoDetailsMiniTooltip } from './details_mini_tooltip/VideoDetailsMiniTooltip';
import useStores from '../../hooks/useStores';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export interface VideoCardProps {
  video: Video;
  disableDetailsTooltip?: boolean
}

const Component: React.FC<VideoCardProps> = (props: VideoCardProps) => {
  const { video, disableDetailsTooltip } = props;
  const classes = useVideoCardStyles();
  const { videoStore } = useStores();

  const onThumbnailClick = (video: Video): void => {
    videoStore.setPlaying(false);
    videoStore.setActive(video);
  };

  return (
    <Box className={classes.root}>
      <VideoDetailsMiniTooltip key={video.id} video={video} disabled={disableDetailsTooltip}>
        <Box className={classes.thumbnailBox} onClick={() => onThumbnailClick(video)}>
          <LazyLoadImage className="thumbnail" src={video.thumbnail[3]?.url} alt={video.title} effect="blur" delayTime={150}/>
          <Typography className="title" variant="h6">
            {video.title}
          </Typography>
        </Box>
      </VideoDetailsMiniTooltip>
    </Box>
  );
};

export const VideoCard = observer(Component);
