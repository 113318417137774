import { makeStyles } from '@material-ui/core/styles';

const iconAndImg = {
  color: 'white',
  '& .MuiSvgIcon-root': {
    color: 'white'
  },
  '& img': {
    filter: 'invert(100%)'
  }
};

const useVideoProspectsStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    '& .categories': {
      overflow: 'hidden',
      borderRadius: 5,
      background: 'white',
      color: theme.palette.secondary.main,
      '& > div': {
        '&:not(:last-child)': {
          borderBottom: '3px solid black'
        }
      },
      '& .category': {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 2),
        '& *': {
          fontWeight: 600,
        },
        '& img': {
          width: 55,
          marginRight: theme.spacing(2)
        },
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: theme.palette.primary.main,
          ...iconAndImg
        },
        '& .MuiSvgIcon-root': {
          fontSize: 50,
          color: theme.palette.primary.main,
          marginRight: theme.spacing(-2),
        },
        '&.selected': {
          backgroundColor: theme.palette.secondary.main,
          ...iconAndImg
        }
      }
    }
  },
  content: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(4)
    }
  },
  tagBox: {
    '& .title': {
      textTransform: 'uppercase',
      lineHeight: '34px',
      fontWeight: 500
    },
    '& .tags': {
      display: 'flex',
      flexWrap: 'wrap',
      padding: theme.spacing(2),
      borderRadius: 5,
      backgroundColor: theme.palette.secondary.main,
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(5),
      },
      '& .tag': {
        flex: '1 0 33%',
        fontWeight: 500,
        marginBottom: theme.spacing(1),
        '&:hover': {
          color: theme.palette.background.paper,
          cursor: 'pointer'
        },
        '&:last-child': {
          flex: '1 0 66%',
        },
        [theme.breakpoints.down('xs')]: {
          flex: '1 0 100%',
        }
      }
    }
  },
  featured: {
    margin: theme.spacing(4, -2, 0, -2)
  }
}));

export default useVideoProspectsStyles;
