import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { getImage } from '../../helpers/AppHelpers';
import { Trans } from 'react-i18next';
import useProfileHeaderStyles from './ProfileHeader.styles';

interface Props {
  titleKey: string;
}

const ProfileHeader = (props: Props) => {

  const classes = useProfileHeaderStyles();

  return (
    <Box className={classes.root}>
      <Box className="logo">
        <img src={getImage('logo-portrait.png')} alt="logo"/>
      </Box>
      <Typography variant="h3" component="div" className="title">
        <Trans i18nKey={props.titleKey}/>
      </Typography>
    </Box>
  );

};

export default ProfileHeader;
