import { makeStyles } from '@material-ui/core';

export const useVideoCardStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 1),
    textAlign: 'center',
    '& img': {
      width: '100%'
    }
  },
  thumbnailBox: {
    position: 'relative',
    cursor: 'pointer',
    overflow: 'hidden',
    '& > .title': {
      lineHeight: 'normal',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 60,
      fontWeight: 600,
      position: 'absolute',
      bottom: 0,
      width: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      padding: theme.spacing(1),
      textShadow: '1px 1px 1px #444444'
    },
    '& .thumbnail': {
      minHeight: 180
    }
  }
}));
