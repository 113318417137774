import { Rating as MuiRating } from '@material-ui/lab';
import { observer } from 'mobx-react';
import React from 'react';
import { useRatingStyles } from './Rating.styles';

export interface RatingProps {
  value: number;
  color?: 'primary' | 'secondary';
  size?: 'small' | 'medium' | 'large';
  onChange?: (v: number) => void;
}

const Component: React.FC<RatingProps> = (props: RatingProps) => {
  const { value, color, size, onChange } = props;
  const classes = useRatingStyles({ color: color || 'secondary' });

  const emit = (ev: any, v: number): void => {
    !!onChange && onChange(v);
  };

  return <MuiRating className={classes.root} size={size || 'large'} value={value} onChange={emit} />;
};

export const Rating = observer(Component);
