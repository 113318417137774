import React from 'react';
import CookieConsent from 'react-cookie-consent';
import useCookiesStyles, { acceptButtonStyle, declineButtonStyle, generalStyle } from './Cookies.style';
import { Box, Grid, Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';

const CookieConsentComponent = () => {
    const cookieClasses = useCookiesStyles();

    return (
        <CookieConsent
        overlay
        containerClasses={cookieClasses.cookiesContainer}
        buttonClasses={cookieClasses.acceptButton}
        declineButtonClasses={cookieClasses.declineButton}
        buttonText={<Trans i18nKey="cookie_consent.accept_all_cookies" />}
        setDeclineCookie={false}
        enableDeclineButton={true}
        declineButtonText='X'
        style={generalStyle}
        buttonStyle={acceptButtonStyle}
        declineButtonStyle={declineButtonStyle}
      >
        <Typography className={cookieClasses.cookieTitle} variant="h5" color="primary">
          <Trans i18nKey="cookie_consent.title" />
        </Typography>
        <Box mt={2}>
          <Typography className={cookieClasses.cookieContent} variant="body2">
            <Trans i18nKey="cookie_consent.text" />
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <a href='#' target='_blank'>
              <Trans i18nKey="cookie_consent.more_information" />
            </a>
          </Grid>
        </Grid>
      </CookieConsent>
    );
};

export default CookieConsentComponent;
