import { makeStyles } from '@material-ui/core/styles';

const useHeaderStyles = makeStyles((theme) => ({
  myAccountTitle: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'center'
    }
  }
}));

export default useHeaderStyles;
