import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { Trans } from 'react-i18next';

export interface ConfirmDialogProps {
  message?: string;
  onClose: (value: boolean) => void;
}

const ConfirmDialog = (props: ConfirmDialogProps) => {
  const { message, onClose } = props;

  return (
    <Dialog open={!!message} onClose={() => onClose(false)} aria-labelledby="confirm-dialog">
      <DialogTitle id="confirm-dialog">
        <Trans i18nKey="global.confirm"/>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={() => onClose(true)}>
          <Trans i18nKey="global.accept"/>
        </Button>
        <Button variant="contained" color="secondary" onClick={() => onClose(false)}>
          <Trans i18nKey="global.cancel"/>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
