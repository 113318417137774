import React from 'react';
import { Box, Link as MuiLink } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import useStores from '../../../hooks/useStores';
import clsx from 'clsx';
import useHeaderLinksStyles from './HeaderLinks.styles';
import { Page } from '../../../constants';


const HeaderLinks = () => {

  const classes = useHeaderLinksStyles();

  const { profileVideosStore } = useStores();
  const { ready } = useTranslation();
  const location = useLocation();

  const selectedClass = (page: string) => clsx({
    selected: location.pathname === page
  }) ;

  const handleMyList = () => {
    profileVideosStore.showList();
  };

  return ready && (
    <Box className={clsx(classes.root, 'links')}>
        <Link to={Page.home} className={selectedClass(Page.home)}>
          <Trans i18nKey="header.home"/>
        </Link>
        <Link to={Page.videoProspects} className={selectedClass(Page.videoProspects)}>
          <Trans i18nKey="header.videoprospects"/>
        </Link>
        <Link to={Page.liveEvents} className={selectedClass(Page.liveEvents)}>
          <Trans i18nKey="header.live"/>
        </Link>
        <MuiLink onClick={handleMyList}>
          <Trans i18nKey="header.my_list"/>
        </MuiLink>
      </Box>
  );

};

export default HeaderLinks;
