import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import AccountHeader from '../account_header/AccountHeader';
import useEditAccountStyles from './EditAccount.styles';
import clsx from 'clsx';
import useStores from '../../hooks/useStores';
import { Trans, useTranslation } from 'react-i18next';
import PlansDialog from './PlansDialog';
import useDialogs from '../../hooks/useDialogs';

const EditAccount = () => {
  const classes = useEditAccountStyles();
  const { userStore } = useStores();
  const { showSnackbar, showSnackbarError } = useDialogs();
  const { user, hasContract } = userStore;
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [openPhoneDialog, setOpenPhoneDialog] = useState(false);
  const [userNewEmail, setUserNewEmail] = useState('');
  const [userConfirmEmail, setUserConfirmEmail] = useState('');
  const [changeEmailError, setChangeEmailError] = useState('');
  const [userPhone, setUserPhone] = useState('');

  const handleSubscriptionManagementSession = async () => {
    await userStore.retrievePortalURL(window.location.href);
    if (userStore.portalURL) {
      window.location.replace(userStore.portalURL);
    } else {
      console.warn('Portal URL not available');
    }
  };

  const handlePasswordChange = async () => {
    await userStore.retrieveChangePasswordURL();
    if (userStore.changePasswordURL) {
      window.open(userStore.changePasswordURL);
    } else {
      console.warn('Change password URL not available');
    }
  };

  const handleChangeEmail = async () => {
    if (!userNewEmail || userNewEmail !== userConfirmEmail) {
      setChangeEmailError(t('edit_account.email_must_be_equal'));
      return;
    }
    setChangeEmailError('');
    const result = await userStore.changeUserEmail(userNewEmail);
    if (result) {
      showSnackbar(t('edit_account.email_change_success'));
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      setOpenEmailDialog(false);
    } else {
      showSnackbarError(t('edit_account.email_change_error'));
      setOpenEmailDialog(false);
    }
  };

  const handleChangePhone = async () => {
    setOpenPhoneDialog(false);
    const changed = await userStore.changeUserPhone(parseInt(userPhone));
    return changed ?
      showSnackbar(t('edit_account.phone_change_success')) :
      showSnackbarError(t('edit_account.phone_change_error'));

  };

  return (
    <>
      <AccountHeader />
      <Box className={classes.background}>
        <Container>
          <Grid container direction="row" className={classes.mainContainer}>
            <Grid item xs={12} md={4}>
              <Box className={classes.titleContainer}>
                <Typography variant="h6" color="primary" className={classes.subscription}>
                  <Trans i18nKey="edit_account.subscription"/>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={8} className={classes.optionsContainer}>
              <Box className={classes.contentContainer}>
                <Grid container direction="row">
                  <Grid item xs={12} sm={8} className={classes.section}>
                    <p>{user.email}</p>
                    <p><Trans i18nKey="edit_account.password"/>: ********</p>
                    {user.phone && (
                      <p>
                        <Trans i18nKey="edit_account.phone"/>: {user.phone}
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.section}>
                    <Box pt={4}>
                      <a onClick={() => setOpenEmailDialog(true)}>
                        <Trans i18nKey="edit_account.change_email"/>
                      </a>
                      <br />
                      <a onClick={handlePasswordChange}>
                        <Trans i18nKey="edit_account.change_password"/>
                      </a>
                      <br />
                      <a onClick={() => setOpenPhoneDialog(true)}>
                        <Trans i18nKey={user.phone ? 'edit_account.change_phone' : 'edit_account.add_phone'}/>
                      </a>
                    </Box>
                  </Grid>
                  <Grid item xs={12} className={clsx(classes.lastSection, classes.sectionDetails)}>
                    <Grid container>
                      <Grid item xs={12} sm={8}>
                        <Box mb={4}>
                          <Typography variant="h6" color="primary">
                            <Trans i18nKey="edit_account.plan_details"/>
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={4} className={classes.accountTypeContainer}>
                        <Box className={classes.accountDetail}>
                          <a onClick={() => setOpenDialog(true)}>
                            <Trans i18nKey="edit_account.show_plans"/>
                          </a>
                          { !hasContract && (
                            <Box mt={ 4 }>
                              <Button variant="contained" color="secondary" onClick={ handleSubscriptionManagementSession }>
                                <Trans i18nKey="edit_account.manage_subscription"/>
                              </Button>
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Box className={classes.littleFooter}>© { new Date().getFullYear() } Careflix. All rights reserved.</Box>
      </Box>
      <PlansDialog openDialog={openDialog} setOpenDialog={setOpenDialog} />

      <Dialog open={openEmailDialog} onClose={() => setOpenEmailDialog(false)}>
        <DialogTitle>
          <DialogTitle disableTypography={true}>
            <Typography variant="h5" color="primary">
              <Trans i18nKey="edit_account.change_email_title"/>
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.changeEmailContent}>
            <form>
              <TextField
                id="new-email"
                type="email"
                className={classes.fullWidthInput}
                autoFocus={true}
                variant="outlined"
                color="primary"
                onChange={(event) => setUserNewEmail(event.target.value)}
                label={t('edit_account.new_email')}
                error={!userNewEmail}
                helperText={t('global.required_field')}
              />
              <br />
              <TextField
                id="confirm-email"
                type="email"
                className={classes.fullWidthInput}
                variant="outlined"
                color="primary"
                onChange={(event) => setUserConfirmEmail(event.target.value)}
                error={!!changeEmailError}
                helperText={changeEmailError ? changeEmailError : ''}
                label={t('edit_account.confirm_new_email')}
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleChangeEmail} variant="contained" color="primary">
              <Trans i18nKey="edit_account.change_email"/>
            </Button>
          </DialogActions>
        </DialogTitle>
      </Dialog>
      <Dialog open={openPhoneDialog} onClose={() => setOpenPhoneDialog(false)}>
        <DialogTitle>
          <DialogTitle disableTypography={true}>
            <Typography variant="h5" color="primary">
              <Trans i18nKey="edit_account.change_phone_title"/>
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.changeEmailContent}>
            <form>
              <TextField
                id="new-phone"
                className={classes.fullWidthInput}
                autoFocus={true}
                type="email"
                defaultValue={user.phone}
                variant="outlined"
                color="primary"
                onChange={(event) => setUserPhone(event.target.value)}
                label={t('edit_account.new_phone')}
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleChangePhone} variant="contained" color="primary">
              <Trans i18nKey="edit_account.change_phone"/>
            </Button>
          </DialogActions>
        </DialogTitle>
      </Dialog>
    </>
  );
};

export default EditAccount;
