import React from 'react';
import { Box, Button } from '@material-ui/core';
import { getCallbackUri, getImage, inEditorOrAuthorMode } from '../../helpers/AppHelpers';
import useOnboardingHeaderStyles from './OnboardingHeader.styles';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const OnboardingHeader = (props: { buttonText: string }) => {

  const classes = useOnboardingHeaderStyles();
  const { loginWithRedirect, loginWithPopup } = useAuth0();

  const login = () => {
    const redirectUri = getCallbackUri();
    const options = { redirectUri };
    return inEditorOrAuthorMode() ?
      loginWithPopup(options) :
      loginWithRedirect(options);
  }

  return (
    <Box className={classes.root}>
      <Box flexGrow={1}>
        <Link to="/">
          <img src={getImage('logo-portrait.png')} alt="logo"/>
        </Link>
      </Box>
      <Box>
        <Button variant="contained" color="primary" onClick={login}>
          {props.buttonText}
        </Button>
      </Box>
    </Box>
  );
};

export default OnboardingHeader;
