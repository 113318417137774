import { fade, makeStyles } from '@material-ui/core/styles';

const useLoadingStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    zIndex: 9999,
    backgroundColor: 'rgba(0,0,0,.8)',
    top: 0,
    left: 0,
    '& .wrapper': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(1),
      background: fade(theme.palette.background.paper, 0.8),
      borderRadius: 100
    }
  }
}));

export default useLoadingStyles;
