import { makeAutoObservable, runInAction } from 'mobx';
import { ProfileInterest, ProfileInterestField } from '../domain/ProfileInterest';
import { getProfileInterests } from '../services/magnolia.http.service';
import { keyBy } from 'lodash';

class UiStore {

  private _selectedCategory?: string;
  private _categoryTags: string[] = [];
  private _loadingInterests = false;
  private _profileInterests: ProfileInterest[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedCategory(category: string) {
    this._selectedCategory = category;
  }

  setCategoryTags(tags: string) {
    this._categoryTags = tags.split(',').map(value => value.trim());
  }

  get categoryTags() {
    return this._categoryTags;
  }

  get profileInterests() {
    return this._profileInterests;
  }

  get profileInterestMap() {
    return keyBy(this._profileInterests, ProfileInterestField.id);
  }

  get selectedCategory() {
    return this._selectedCategory;
  }

  async loadProfileInterests() {
    if (!this._profileInterests?.length) {
      try {
        this._loadingInterests = true;
        const { data } = await getProfileInterests();
        runInAction(() => {
          this._profileInterests = data;
          this._loadingInterests = false;
        });
      } catch (e) {
        this._loadingInterests = false;
      }
    }
  }

}

export default UiStore;
