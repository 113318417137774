import { makeStyles } from '@material-ui/core/styles';

const useSignupInfoItemStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(5),
    paddingRight: theme.spacing(2),
    '& .image img': {
      width: 80,
      height: 80,
      marginRight: theme.spacing(2)
    }
  }
}));

export default useSignupInfoItemStyles;
