import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Lang } from '../constants';

const { REACT_APP_STRIPE_KEY } = process.env;

let stripePromise: Promise<Stripe | null>;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(REACT_APP_STRIPE_KEY, { locale: Lang.es });
  }
  return stripePromise;
};

export const redirectToCheckout = async (sessionId: string) => {
  const stripe = await getStripe();
  await stripe.redirectToCheckout({ sessionId });
}