import { makeStyles } from '@material-ui/core';
import { Video } from '../../domain/Video';

export const getContainerBackgroundStyle = (video: Video): Record<string, string> => {
  return {
    background: `linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2)), url(${video.thumbnail[4]?.url}) center/cover no-repeat no-repeat`,
    backgroundBlendMode: 'darken'
  };
};

export const useVideoDetailsDialogStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      '& .MuiDialog-paper': {
        width: '100vw',
        margin: theme.spacing(1.5)
      }
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
    minHeight: '60vh',
    padding: theme.spacing(4),
    '& > *': {
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
      zoom: 0.65
    }
  },
  title: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    maxWidth: '75%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  rating: {},
  buttonsAndTags: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& .MuiFab-root': {
      minWidth: theme.spacing(6)
    },
    '& > *': {
      marginRight: theme.spacing(2)
    },
    '& .tags > *': {
      margin: theme.spacing(0, 2, 1, 0)
    }
  },
  playButton: {},
  description: {}
}));
