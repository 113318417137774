import { makeAutoObservable, runInAction } from 'mobx';
import { Video } from '../domain/Video';
import { get, post } from '../services/http.service';
import ProfileStore from './ProfileStore';
import { keyBy, mapValues } from 'lodash';
import { ProfileVideoRating, ProfileVideoRatingField } from '../domain/ProfileVideoRating';

class ProfileRatingsStore {
  private readonly profileStore: ProfileStore;
  private _loading = false;
  private _ratingMap: { [key: string]: number } = {};

  constructor(profileStore: ProfileStore) {
    makeAutoObservable(this);
    this.profileStore = profileStore;
  }

  get loading(): boolean {
    return this._loading;
  }

  getRating(videoId: string) {
    return this._ratingMap[videoId] ?? 0;
  }

  private setLoading(value: boolean) {
    this._loading = value;
  }

  private setRatingMap(data: ProfileVideoRating[]) {
    const videoRatingsMap = keyBy(data, ProfileVideoRatingField.vendorId);
    this._ratingMap = mapValues(videoRatingsMap, ProfileVideoRatingField.rating);
  }

  async fetchRatings(): Promise<void> {
    try {
      const { selectedProfile } = this.profileStore;
      this.setLoading(true);
      const { data } = await get<ProfileVideoRating[]>(`/user-profile/${selectedProfile.id}/ratings`);
      this.setRatingMap(data);
    } finally {
      this.setLoading(false);
    }
  }

  async saveRating(v: Video, rating: number): Promise<boolean> {
    let retVal = true;
    try {
      this.setLoading(true);
      const { selectedProfile } = this.profileStore;
      await post<void, any>(`/user-profile/${selectedProfile.id}/video/${v.id}/rating/${rating}`);
      runInAction(() => {
        this._ratingMap[v.id] = rating;
      });
    } catch (ex) {
      retVal = false;
    } finally {
      this.setLoading(false);
    }
    return retVal;
  }

}

export default ProfileRatingsStore;
