import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import Image, { MagnoliaImage } from '../image/Image';
import useSignupInfoItemStyles from './SignupInfoItem.styles';

interface Props {
  image: MagnoliaImage;
  text: string;
}

const SignupInfoItem = (props: Props) => {

  const { image, text } = props;
  const classes = useSignupInfoItemStyles();

  return (
    <Grid item xs={12} lg={6} className={classes.root}>
      <Box className="image">
        <Image image={image}/>
      </Box>
      <Box flexGrow={1}>
        <Typography variant="h6">
          {text}
        </Typography>
      </Box>
    </Grid>
  );

};


export default SignupInfoItem;
