import { Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import Carousel, { ResponsiveType } from 'react-multi-carousel';
import useStores from '../../hooks/useStores';
import { VideoCard } from '../video_card/VideoCard';
import useTagsVideosCarousel from './TagsVideosCarousel.styles';
import Loading from '../loading/Loading';
import { Trans } from 'react-i18next';
import { getCarouselResponsive } from '../../helpers/AppHelpers';

interface Props {
  title: string;
  tags: string;
  responsive?: ResponsiveType
}

const TagsVideosCarousel = (props: Props) => {
  const { title, tags } = props;
  const classes = useTagsVideosCarousel();
  const { videoStore } = useStores();
  const videos = videoStore.getTagsVideos(tags);
  const responsive = props.responsive ?? getCarouselResponsive();
  const loading = videoStore.isTagLoading(tags);

  useEffect(() => {
    videoStore.loadVideosByTags(tags).then();
  }, [tags, videoStore]);


  return (
    <Box className={classes.root}>
      {loading && <Loading/>}
      <Typography variant='h4' color='primary' className={classes.title}>
        {title}
      </Typography>

      <Carousel arrows={true} infinite={true} responsive={responsive}>
        {videos?.map((video) => (
          <VideoCard key={video.id} video={video}/>
        ))}
      </Carousel>

      {!loading && !videos?.length && (
        <Box textAlign='center' mt={2}>
          <Typography variant='h4'>
              <Trans i18nKey='global.no_videos_found'/>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default observer(TagsVideosCarousel);
