import { makeStyles } from '@material-ui/core/styles';

const useSuccessPaymentStyles = makeStyles((theme) => ({
  root: {
    background: 'url("/images/opacity-logo.png") center center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'white',
    width: '100vw',
    height: '100vh',
    '& .MuiPaper-root': {
      background: 'rgba(255,255,255,0.6)'
    },
    '& .checkIcon': {
      width: '180px',
      height: '180px',
      position: 'relative',
      display: 'inline-block'
    },
    '& .MuiGrid-item': {
      textAlign: 'center'
    },
    '& .MuiTypography-body1': {
      color: 'black'
    }
  }
}));

export default useSuccessPaymentStyles;
