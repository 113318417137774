import React, { useEffect, useState } from 'react';
import config from '../magnolia.config';
import { getAPIBase, getProfileLang, removeCurrentLanguage, removeLastSlash } from './AppHelpers';
import { EditablePage, EditorContextHelper } from '@magnolia/react-editor';
import { MGNL_API_PAGES, MGNL_API_TEMPLATES, MGNL_APP_BASE } from '../constants';
import Loading from '../components/loading/Loading';

interface PageLoaderState {
  init: boolean,
  pathname: string,
  content: any,
  templateDefinitions: any
}

const PageLoader = () => {

  const [state, setState] = useState<PageLoaderState>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    loadPage().then(() => {
      setLoading(false);
    });
  }, [window.location.pathname]);

  const getPagePath = () => {
    const nodeName = MGNL_APP_BASE;
    const profileLang = getProfileLang();
    const path = nodeName + window.location.pathname.replace(new RegExp(`(.*${nodeName}|.html)`, 'g'), '');
    const lang = profileLang ? `?lang=${ profileLang}` : '';
    return `${removeCurrentLanguage(path)}${lang}`;
  };

  const loadPage = async () => {
    // Bail out if already loaded content.
    if (state && removeLastSlash(state.pathname) === removeLastSlash(window.location.pathname)) {
      return;
    }

    const apiBase = getAPIBase();
    const pagePath = getPagePath();
    console.log('pagePath:', pagePath);
    let fullContentPath = apiBase + MGNL_API_PAGES + pagePath;
    console.log('fullContentPath:', fullContentPath);

    const pageResponse = await fetch(fullContentPath);
    const pageJson = await pageResponse.json();
    console.log('page content: ', pageJson);

    let templateJson = null;
    if (EditorContextHelper.inEditor()) {
      const templateId = pageJson['mgnl:template'];
      console.log('templateId:', templateId);
      const templateResponse = await fetch(apiBase + MGNL_API_TEMPLATES + '/' + templateId);
      templateJson = await templateResponse.json();
      console.log('definition:', templateJson);
    }

    setState({
      init: true,
      content: pageJson,
      templateDefinitions: templateJson,
      pathname: window.location.pathname
    });
  };

  return state?.init ? (
    <>
      {loading && <Loading/>}
      <EditablePage
        templateDefinitions={state.templateDefinitions}
        content={state.content}
        config={config}>
      </EditablePage>
    </>
  ) : <Loading/>;
}

export default PageLoader;
