import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import RichText from '../rich_text/RichText';
import OnboardingHeader from '../onboarding_header/OnboardingHeader';
import useOnboardingIntroStyles from './OnboardingIntro.styles';
import { useAuth0 } from '@auth0/auth0-react';
import FullScreenBackground from '../full_screen_background/FullScreenBackground';
import { getCallbackUri } from '../../helpers/AppHelpers';

interface Props {
  title: string;
  subtitle: string;
  loginText: string;
  logonText: string;
}

const OnboardingIntro = (props: Props) => {

  const { title, subtitle, loginText, logonText } = props;
  const classes = useOnboardingIntroStyles();
  const { loginWithRedirect } = useAuth0();

  const signup = () => {
    loginWithRedirect({
      screen_hint: 'signup',
      redirectUri: getCallbackUri()
    }).then();
  }

  return (
    <Box className={classes.root}>
      <FullScreenBackground/>
      <OnboardingHeader buttonText={loginText}/>
      <Typography variant="h1">
        <RichText text={title}/>
      </Typography>
      <Typography variant="h3">
        <RichText text={subtitle}/>
      </Typography>

      <Button variant="contained" color="secondary" className="logon-button"
        endIcon={<NavigateNextIcon/>}
        onClick={signup}
      >
        {logonText}
      </Button>
    </Box>
  );

};


export default OnboardingIntro;
