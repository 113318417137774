import { makeStyles } from '@material-ui/core/styles';

const useProfileEditorStyles = makeStyles((theme) => ({
  root: {
    '& > .content': {
      marginTop: theme.spacing(8),
      '& .image-box': {
        textAlign: 'right',
        position: 'relative',
        paddingRight: theme.spacing(10),
        '& .image': {
          border: '5px solid white',
          maxHeight: 250,
          maxWidth: '100%',
          borderRadius: 20
        },
        '& .profile-icon': {
          fontSize: 200
        },
        '& .edit-button': {
          position: 'absolute',
          bottom: 0,
          right: theme.spacing(8),
          height: 64,
          borderRadius: 32
        }
      },
      '& form > *': {
        marginBottom: theme.spacing(2)
      },
      '& .buttons': {
        marginTop: theme.spacing(4),
        display: 'flex',
        justifyContent: 'center',
        '& .MuiButton-root': {
          margin: theme.spacing(0, 1)
        }
      },
      '& .hidden': {
        display: 'none'
      },
      '& .image-alert': {
        textAlign: 'center',
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          padding: theme.spacing(3, 6, 0, 0),
          textAlign: 'right'
        }
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(4),
        '& .image-box': {
          textAlign: 'center',
          paddingRight: 0,
          marginBottom: theme.spacing(4)
        }
      }
    }
  },
  imageSelectorToolbar: {
    display: 'flex'
  },
  interestsRender: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0, 0.5, 0.5, 0)
    }
  },
  imageSelector: {
    marginTop: theme.spacing(2),
    display: 'flex',
    '& .MuiGrid-container': {
      height: 0,
    },
    '& img': {
      width: '100%',
      aspectRatio: 1,
      border: '4px solid white',
      borderRadius: 10,
      '&:hover': {
        cursor: 'pointer',
        borderColor: theme.palette.primary.main
      },
      '&.selected': {
        borderColor: theme.palette.secondary.main
      }
    }
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  }
}));

export default useProfileEditorStyles;
