import React, { useState } from 'react';
import { AppBar, Box, Drawer, IconButton, Toolbar, useMediaQuery, useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { getImage } from '../../helpers/AppHelpers';
import useHeaderStyles from './Header.styles';
import MenuIcon from '@material-ui/icons/Menu';
import HeaderOptions from './options/HeaderOptions';
import HeaderLinks from './links/HeaderLinks';
import { UserVideosDialog } from '../user_videos_dialog/UserVideosDialog';
import { VideoDetailsDialog } from '../video_details_dialog/VideoDetailsDialog';
import { VideoPlayer } from '../video_player/VideoPlayer';
import { VideoSearchDialog } from '../video_search_dialog/VideoSearchDialog';

const Header = () => {

  const classes = useHeaderStyles();
  const [showMenu, setShowMenu] = useState(false);
  const theme = useTheme();
  const isDownXs = useMediaQuery(theme.breakpoints.down('xs'));


  return (
    <>
      <AppBar position="sticky" className={classes.root}>
        <Toolbar>
          <Link to="/" className="logo">
            <img src={getImage('logo.png')} alt="logo"/>
          </Link>

          {isDownXs ? (
            <>
              <IconButton onClick={() => setShowMenu(true)}>
                <MenuIcon/>
              </IconButton>
              <Drawer anchor="right" open={showMenu} onClose={() => setShowMenu(false)}>
                <Box className={classes.menu}>
                  <HeaderOptions/>
                  <HeaderLinks/>
                </Box>
              </Drawer>
            </>
          ) : (
            <>
              <HeaderLinks/>
              <HeaderOptions/>
            </>
          )}
        </Toolbar>
      </AppBar>
      <UserVideosDialog />
      <VideoDetailsDialog />
      <VideoPlayer />
      <VideoSearchDialog />
    </>
  );

}

export default Header;
