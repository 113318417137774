import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { get } from '../services/http.service';
import { getLiveEvents } from '../services/magnolia.http.service';
import { LiveEvent } from '../domain/LiveEvent';
import { keyBy, uniq } from 'lodash';
import { Video } from '../domain/Video';
import moment from 'moment-timezone';
import { MomentUnit } from '../constants';


class LiveEventStore {

  private _loading = false;
  private _events: LiveEvent[] = []
  private _selectedEvent: LiveEvent;

  constructor() {
    makeAutoObservable(this);
  }

  get todayEvents() {
    const date = moment().add(1, MomentUnit.days).startOf(MomentUnit.day);
    return this._events
      .filter(event => date.isAfter(event.date));
  }

  get futureEvents() {
    const date = moment().add(1, MomentUnit.days).startOf(MomentUnit.day);
    return this._events
      .filter(event => date.isBefore(event.date));
  }

  get loading() {
    return this._loading;
  }

  get selectedEvent() {
    return this._selectedEvent;
  }

  setSelectedEvent(event?: LiveEvent) {
    this._selectedEvent = toJS(event);
  }


  async loadLiveEvents() {
    try {
      this._loading = true;
      const { data: events } = await getLiveEvents();
      const ids = uniq(events?.map(event => event.shooowitId));
      const { data } = await get<Video[]>(`/vimeo/videos/ids?ids=${ids}`);
      const videosMap = keyBy(data, video => video.id);
      runInAction(() => {
        this._events = events
          .filter(event => videosMap[event.shooowitId])
          .map(event => ({
            ...event,
            video: videosMap[event.shooowitId]
          }));
        this._loading = false;
      });
    } catch (e) {
      this._loading = false;
    }
  }


}

export default LiveEventStore;
