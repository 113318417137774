import { makeAutoObservable, runInAction } from 'mobx';
import { get } from '../services/http.service';
import { Plan } from '../domain/Plan';


class PlanStore {

  loading = false;
  selectedPlanId?: number;
  plans: Plan[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedPlanId(id: number) {
    this.selectedPlanId = id;
  }

  async loadPlans() {
    try {
      this.loading = true;
      const { data } = await get<Plan[]>('/plans');
      runInAction(() => {
        this.plans = data;
        this.loading = false;
      });
    } catch (e) {
      this.loading = false;
    }
  }

}

export default PlanStore;
