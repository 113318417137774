import orange from '@material-ui/core/colors/orange';
import { makeStyles } from '@material-ui/core/styles';

const useOnboardingFaqsStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(8, 2),
    '& h2': {
      fontWeight: 600,
      textAlign: 'center'
    },
    '& .questions': {
      width: '50vw',
      minWidth: 600,
      minHeight: '50vh',
      marginTop: theme.spacing(6),
      '& .MuiAccordion-root': {
        marginBottom: theme.spacing(1.5),
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 5,
        '& .MuiAccordionSummary-content': {
          alignItems: 'center'
        },
        '& .MuiCollapse-wrapper': {
          backgroundColor: orange[500],
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5
        },
        '& .MuiSvgIcon-root': {
          fontSize: '2.5rem'
        },
        '& .arrow': {
          marginRight: theme.spacing(2)
        }
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        minWidth: 'inherit'
      }
    }
  }
}));

export default useOnboardingFaqsStyles;
