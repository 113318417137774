import React, { useEffect } from 'react';
import { EditableArea } from '@magnolia/react-editor';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import useSignupStyles from './Signup.styles';
import { useHistory } from 'react-router-dom';
import { GenderType, UserRequest } from '../../domain/User';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { createMuiTheme } from '@material-ui/core/styles';
import useStores from '../../hooks/useStores';
import { observer } from 'mobx-react';
import provinces from './provinces_es.json';
import theme from '../../theme';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { inEditorOrAuthorMode } from '../../helpers/AppHelpers';
import useDialogs from '../../hooks/useDialogs';
import { Lang, Page } from '../../constants';
import Loading from '../../components/loading/Loading';


interface Props {
  infoHeader: object;
  infoItems: object;
  infoFooter: object;
}

const Signup = (props: Props) => {

  const classes = useSignupStyles();
  const { infoHeader, infoItems, infoFooter } = props;
  const { register, errors, control, watch, handleSubmit } = useForm();
  const { t } = useTranslation();
  const { showSnackbarError } = useDialogs();
  const { planStore, userStore } = useStores();
  const { user, hasContract, loading } = userStore;
  const history = useHistory();
  const isInEditor = inEditorOrAuthorMode();
  const acceptTerms = watch('acceptTerms', false);
  const required = 'global.required_field';

  const lightTheme = createMuiTheme({
    palette: {
      type: 'light',
      primary: theme.palette.primary,
      secondary: theme.palette.secondary
    }
  });

  const countries = [{
    id: Lang.es,
    name: 'España'
  }];

  useEffect(() => {
    if (!isInEditor && user?.id) {
      history.push(Page.home);
    }
  }, [user]);

  const onSubmit = async (data) => {
    if (!isInEditor) {
      const userData: UserRequest = {
        ...user,
        ...data,
        planId: planStore.selectedPlanId
      };
      await userStore.saveUser(userData);
      !hasContract && await userStore.createSubscription(planStore.selectedPlanId);
    }
  };

  if (userStore.error) {
    showSnackbarError(t('global.unexpected_error'));
    userStore.resetError();
  }

  return (
    <Box className={classes.root}>
      {loading && <Loading/>}
      <Grid container>
        <ThemeProvider theme={lightTheme}>
          <Grid item xs={12} md={6} className="form">
            <Typography variant="h3" color="primary" className="title">
              <Trans i18nKey="signup.title"/>
            </Typography>
            <Typography variant="h5" className="subtitle">
              <Trans i18nKey="signup.subtitle"/>
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <TextField
                fullWidth
                name="email"
                label={t('user_form.email')}
                defaultValue={user?.email}
                disabled={true}
              />
              <TextField
                fullWidth
                name="name"
                label={t('user_form.name')}
                defaultValue={user?.name}
                error={!!errors.name}
                helperText={t(errors.name?.message)}
                inputRef={register({ required })}
              />
              <TextField
                fullWidth
                name="surnames"
                label={t('user_form.surnames')}
                error={!!errors.surnames}
                helperText={t(errors.surnames?.message)}
                inputRef={register({ required })}
              />
              <FormControl error={!!errors.gender} className="gender">
                <FormLabel>
                  <Trans i18nKey="user_form.gender"/>
                </FormLabel>
                <Controller
                  name="gender"
                  rules={{ required }}
                  control={control}
                  defaultValue=""
                  as={(
                    <RadioGroup row>
                      <FormControlLabel
                        value={GenderType.MALE}
                        control={<Radio color="primary"/>}
                        label={t('user_form.male')}
                      />
                      <FormControlLabel
                        value={GenderType.FEMALE}
                        control={<Radio color="primary"/>}
                        label={t('user_form.female')}
                      />
                      <FormControlLabel
                        value={GenderType.NO_BINARY}
                        control={<Radio color="primary"/>}
                        label={t('user_form.no_binary')}
                      />
                    </RadioGroup>
                  )}
                />
                <FormHelperText>{t(errors.gender?.message)}</FormHelperText>
              </FormControl>
              <FormControl error={!!errors.birthdate} fullWidth>
                <Controller
                  name="birthdate"
                  control={control}
                  defaultValue={null}
                  rules={{ required }}
                  render={({ ref, ...rest }) => (
                    <KeyboardDatePicker
                      defaultChecked={false}
                      label={t('user_form.birthdate')}
                      error={!!errors.birthdate}
                      format="DD/MM/YYYY"
                      invalidDateMessage={t('global.invalid_date')}
                      {...rest}
                    />
                  )}
                />
                <FormHelperText>{t(errors.birthdate?.message)}</FormHelperText>
              </FormControl>
              <TextField
                fullWidth
                type="number"
                name="phone"
                label={t('user_form.phone')}
                error={!!errors.phone}
                helperText={t(errors.phone?.message)}
                inputRef={register({ required })}
              />
              <TextField
                fullWidth
                name="insurance"
                label={t('user_form.insurance')}
                inputRef={register}
              />
              <FormControl fullWidth error={!!errors.isoCountryCode}>
                <InputLabel id="country">{t('user_form.country')}</InputLabel>
                <Controller
                  name="country"
                  defaultValue="es"
                  rules={{ required }}
                  control={control}
                  as={
                    <Select>
                      {countries.map((country) => (
                        <MenuItem key={country.id} value={country.id}>
                          {country.name}
                        </MenuItem>
                      ))}
                      <MenuItem value="" key={0}/>
                    </Select>
                  }
                />
                <FormHelperText>{t(errors.isoCountryCode?.message)}</FormHelperText>
              </FormControl>

              <FormControl fullWidth error={!!errors.province}>
                <InputLabel>{t('user_form.province')}</InputLabel>
                <Controller
                  name="province"
                  defaultValue=""
                  rules={{ required }}
                  control={control}
                  as={
                    <Select>
                      {provinces.map((p) => (
                        <MenuItem key={p} value={p}>
                          {p}
                        </MenuItem>
                      ))}
                      <MenuItem value="" key={0}/>
                    </Select>
                  }
                />
                <FormHelperText>{t(errors.province?.message)}</FormHelperText>
              </FormControl>
              <TextField
                fullWidth
                name="city"
                label={t('user_form.city')}
                error={!!errors.city}
                helperText={t(errors.city?.message)}
                inputRef={register({ required })}
              />
              <TextField
                fullWidth
                name="howMetUs"
                label={t('user_form.how_met_us')}
                inputRef={register}
              />
              <FormControlLabel
                label={(
                  <Trans i18nKey="signup.accept_terms">
                    He leído y acepto. <Link href="#" target="_blank">los términos y las condiciones legales</Link>.
                  </Trans>
                )}
                control={
                  <Controller
                    control={control}
                    name="acceptTerms"
                    defaultValue={acceptTerms}
                    render={({ onChange, value }) => (
                      <Checkbox color="primary" checked={value} onChange={e => onChange(e.target.checked)}/>
                    )}
                  />
                }
              />

              <Box pt={2}>
                <Button type="submit" color="secondary" variant="contained" disabled={!acceptTerms}>
                  <Trans i18nKey="global.save"/>
                </Button>
              </Box>
            </form>
          </Grid>
        </ThemeProvider>
        <Grid item xs={12} md={6} className="info">
          {infoHeader && (
            <EditableArea className="header" content={infoHeader}/>
          )}
          {infoItems && (
            <EditableArea className="items MuiGrid-root MuiGrid-container" content={infoItems}/>
          )}
          {infoFooter && (
            <EditableArea className="footer" content={infoFooter}/>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default observer(Signup);
