export enum ProfileInterestField {
  id = 'id',
  name = 'name',
  category = 'category',
  tags = 'tags'
}

export interface ProfileInterest {
  [ProfileInterestField.id]: string;
  [ProfileInterestField.name]: string;
  [ProfileInterestField.category]: string;
  [ProfileInterestField.tags]: string;
}
