import React, { useEffect, useState } from 'react';
import { Avatar, Box, IconButton, Link, Popover, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import { useAuth0 } from '@auth0/auth0-react';
import LogoutButton from '../../logout_button/LogoutButton';
import useStores from '../../../hooks/useStores';
import useHeaderOptionsStyles from './HeaderOptions.styles';
import { observer } from 'mobx-react';
import { getDamImage } from '../../../helpers/AppHelpers';
import { Page } from '../../../constants';
import { Profile } from '../../../domain/Profile';
import { Trans } from 'react-i18next';

const HeaderOptions = observer(() => {

  const classes = useHeaderOptionsStyles();
  const { profileStore, userStore, videoStore, profileRatingsStore } = useStores();
  const { user } = useAuth0();
  const [anchorEl, setAnchorEl] = useState<any>();
  const profile = profileStore.selectedProfile;

  useEffect(() => {
    if (!!user && !!profile) {
      profileRatingsStore.fetchRatings().then();
    }
  }, [user, profile]);

  const handleSearch = () => {
    videoStore.openSearch();
  };

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const selectProfile = (profile: Profile) => {
    profileStore.setSelectedProfile(profile);
    closePopover();
  };

  return !!user && (
    <Box className={classes.root}>
      <IconButton onClick={handleSearch}>
        <SearchIcon/>
      </IconButton>
      {profile && (
        <IconButton edge="end" onClick={handleClick} className="profile-button">
          <img src={getDamImage(profile.imageId)} alt="profile-image" className={classes.profileImage}/>
        </IconButton>
      )}

      <Popover
        className={classes.profilePopover}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <Box p={2} display="flex" flexDirection="column">
          <Box display="flex" alignItems="center" mb={3}>
            <Avatar src={user.picture} alt="avatar"/>
            <Box ml={1}>
              <Typography variant="h6">
                {userStore.userName}
              </Typography>
              {user.email}
            </Box>
          </Box>
          <Box>
            {profileStore.profiles.filter(it => it.id !== profile?.id).map(profile => (
              <Box key={profile.id} className="profile" onClick={() => selectProfile(profile)}>
                <img src={getDamImage(profile.imageId)} alt="profile-image" className={classes.profileImage}/>
                {profile.name}
              </Box>
            ))}
          </Box>
          <Link component={RouterLink} to={Page.selectProfile} className="manage-profiles">
            <Trans i18nKey="global.manage_profiles"/>
          </Link>
          <Link component={RouterLink} to={Page.accountEdit} className="manage-profiles">
            <Trans i18nKey="global.edit_account"/>
          </Link>
          <LogoutButton/>
        </Box>
      </Popover>

    </Box>
  );
});

export default HeaderOptions;
