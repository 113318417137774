import { Grid } from '@material-ui/core';
import React from 'react';

interface Props {
    link: string,
    text: string
}

const FooterLinkItem = (props: Props) => {

    const { link, text } = props;

    return (
        <Grid
            item
            xs={12}
            sm={3}
        >
            <a href={link}  target="_blank" rel="noreferrer">
                {text}
            </a>
        </Grid>
    );

};

export default FooterLinkItem;