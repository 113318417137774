import { makeStyles } from '@material-ui/core/styles';

const useOnboardingLinksStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(8, 2)
    },
    '& .title': {
      fontWeight: 600,
      textAlign: 'center',
      '& span': {
        marginLeft: theme.spacing(2),
        fontWeight: 600
      }
    },
    '& .items': {
      margin: theme.spacing(6, 0),
      minWidth: '50vw',
      minHeight: '50vh',
      '& > div': {
        display: 'flex',
        flexWrap: 'wrap'
      },
      '& .item': {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        borderBottom: `1px solid ${ theme.palette.secondary.main }`,
        flex: '1 0 25%',
        '& .text': {
          width: 250
        },
        '&:hover': {
          background: theme.palette.background.paper,
          textDecoration: 'none',
          cursor: 'pointer'
        },
        '&:last-child': {
          flex: 8
        }
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        minWidth: 'inherit',
      }
    }
  }
}));

export default useOnboardingLinksStyles;
