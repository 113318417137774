import { makeAutoObservable, runInAction } from 'mobx';
import { post } from '../services/http.service';
import { PlayToken } from '../domain/PlayToken';


class PlayTokenStore {
  loading = false;
  playToken: PlayToken;
  createdAt: Date;


  constructor() {
    makeAutoObservable(this);
  }

  private shouldGetNewToken(): boolean {
    if (!this.playToken || !this.playToken.token) {
      return true;
    }
    let passedTimeInSeconds = (Date.now() - this.createdAt.getTime()) / 1000;
    return passedTimeInSeconds >= this.playToken.expiresIn / 2;
  }

  get token(): string {
    return (!this.shouldGetNewToken()) ? this.playToken.token : null;
  }

  async fetchToken() {
    if (!this.shouldGetNewToken()) {
      this.loading = false;
      return;
    }
    try {
      this.loading = true;
      this.createdAt = new Date();
      this.playToken = null;
      const { data } = await post<PlayToken, any>('/play-token');
      runInAction(() => {
        this.playToken = data;
        this.loading = false;
      });
    } catch (e) {
      this.loading = false;
    }
  }
}

export default PlayTokenStore;
