import React, { useEffect, useState } from 'react';
import useStores from '../../../hooks/useStores';
import useLiveEventDialogStyles from './LiveEventDialog.styles';
import { Box, Button, Dialog, DialogContent, Grid, IconButton, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import CloseIcon from '@material-ui/icons/Close';
import { formatDate, formatTime, formatVideoDuration } from '../../../utils/AppUtils';
import { Trans } from 'react-i18next';
import PlayIcon from '@material-ui/icons/PlayArrow';
import moment from 'moment';
import { toJS } from 'mobx';


const LiveEventDialog = observer(() => {

  const { liveEventStore, videoStore } = useStores();
  const { selectedEvent: event } = liveEventStore;
  const [ isPlayable, setIsPlayable ] = useState(false);
  const classes = useLiveEventDialogStyles({ image: event?.video?.thumbnail[0]?.url });

  useEffect(() => {
    if(event) {
      const date = moment(event.date).utc();
      const timeDifference = moment().tz('Europe/Madrid').diff(date);
      setIsPlayable(timeDifference > 0 && timeDifference < event.video.duration * 1000);
      videoStore.setIsLiveEvent(true);
      videoStore.setActive(toJS(event.video));
    }
  }, [event]);

  const handleClose = () => {
    liveEventStore.setSelectedEvent();
    videoStore.setPlaying(false);
    videoStore.setActive(null);
    videoStore.setIsLiveEvent(false);
  }

  const handlePlay = () => {
    videoStore.setPlaying(true);
  };

  return !!event && (
    <Dialog
      className={classes.root}
      fullWidth={true}
      maxWidth="md"
      open={!!liveEventStore.selectedEvent}
      onClose={handleClose}
    >
      <DialogContent>
        <Box display="flex" mb={2}>
          <Typography variant="h3" className="title nowrap">
            {event.name}
          </Typography>
          <IconButton className="close" onClick={() => liveEventStore.setSelectedEvent()}>
            <CloseIcon/>
          </IconButton>
        </Box>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography variant="h3" className="title" color="primary">
              {formatDate(event.date)}
            </Typography>
            <Typography variant="h4" className="title">
              {event.organiser}
            </Typography>
            <Box mt={4}>
              <Typography variant="h4" className="title">
                <Trans i18nKey="live_event.hour" values={{ hour: formatTime(event.date) }}/>
              </Typography>
              <Typography variant="h4" className="title">
                <Trans i18nKey="live_event.duration" values={{ value: formatVideoDuration(event.video) }}/>
              </Typography>
              <Box mt={2}>
                <Button
                  className={classes.playButton}
                  variant="contained"
                  color="secondary"
                  disabled={!isPlayable}
                  onClick={() => handlePlay()}
                >
                  <PlayIcon /> Reproducir
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h4" className="title">
              {event.video.title}
            </Typography>
            <Typography variant="body1" className="description">
              {event.video.description}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );

});

export default LiveEventDialog;
