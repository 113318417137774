import { makeAutoObservable, runInAction } from 'mobx';
import { get, post, put } from '../services/http.service';
import { User, UserContract, UserRequest } from '../domain/User';
import { InvalidSubscriptionStatuses, MGNL_APP_BASE, MGNL_BASE_PUBLIC, Page } from '../constants';
import { redirectToCheckout } from '../services/stripe.service';


class UserStore {

  loading = false;
  currentUserLoaded = false;
  verificationEmailSent = false;
  error = false;
  subscriptionStatus: string = null;
  user?: User;
  portalURL: string;
  changePasswordURL: string;
  hasContract = false;

  constructor() {
    makeAutoObservable(this);
  }

  get userName() {
    return `${this.user?.name} ${this.user?.surnames}`;
  }

  setUser(user: User) {
    this.user = user;
  }

  setPortalURL(url: string) {
    this.portalURL = url;
  }

  setChangePasswordURL(url: string) {
    this.changePasswordURL = url;
  }

  setSubscriptionStatus(subscription: string) {
    this.subscriptionStatus = subscription;
  }

  resetError() {
    this.error = false;
  }

  async sendEmailVerification() {
    try {
      this.loading = true;
      await post<void, void>('/users/verification-email');
      runInAction(() => {
        this.verificationEmailSent = true;
        this.loading = false;
      });
    } catch (e) {
      this.loading = false;
    }
  }

  async loadCurrentUser() {
    try {
      this.loading = true;
      const { data: user } = await get<User>('/users/current');
      const { data } = await get<UserContract>('/users/contract');
      runInAction(() => {
        this.user = user;
        this.hasContract = data.hasContract;
        this.loading = false;
        this.currentUserLoaded = true;
      });
    } catch (e) {
      this.loading = false;
    }
  }

  async saveUser(userRequest: UserRequest) {
    try {
      this.loading = true;
      const { data } = await post<UserRequest, User>('/users/current', userRequest);
      runInAction(() => {
        this.user = data;
        this.loading = false;
      });
    } catch (e) {
      this.loading = false;
      this.error = true;
    }
  }

  async loadSubscriptionStatus() {
    try {
      const { data } = await get<any>('/subscriptions/status');
      runInAction(() => {
        this.setSubscriptionStatus(data?.status);
      });
    } catch (e) {
      this.setSubscriptionStatus(InvalidSubscriptionStatuses.INCOMPLETE);
    }
  }

  async retrievePortalURL(url) {
    try {
      this.loading = true;
      const { data } = await post<Object, string>('/customer-portal/session', url);
      this.setPortalURL(data);
      this.loading = false;
    } catch (e) {
      this.loading = false;
    }
  }

  async retrieveChangePasswordURL() {
    try {
      this.loading = true;
      const { data } = await put<any, null>('/users/password');
      this.setChangePasswordURL(data);
      this.loading = false;
    } catch (e) {
      this.loading = false;
    }
  }

  async changeUserEmail(email: string): Promise<boolean> {
    try {
      await put<any, string>('/users/email', email);
      return true;
    } catch (e) {
      return false;
    }
  }

  async changeUserPhone(phone: number): Promise<boolean> {
    try {
      await put<any, number>('/users/phone', phone);
      this.user.phone = phone;
      return true;
    } catch (e) {
      return false;
    }
  }

  async createSubscription(planId: number) {
    const returnURL = window.location.origin + MGNL_BASE_PUBLIC + MGNL_APP_BASE;

    try {
      this.loading = true;
      const { data } = await post<Object, string>('/subscriptions', {
        planId: planId,
        successUrl: returnURL + Page.paymentSuccess,
        cancelUrl: returnURL + Page.paymentFailed
      });
      await redirectToCheckout(data);
      this.loading = false;
    } catch (e) {
      this.loading = false;
    }
  }

}

export default UserStore;
