export enum Lang {
  es = 'es'
}

export enum Page {
  home = '/',
  onboarding = '/onboarding',
  videoProspects = '/videoprospects',
  signup = '/signup',
  selectProfile = '/select-profile',
  editProfile = '/edit-profile',
  liveEvents = '/live-events',
  accountEdit = '/edit-account',
  paymentSuccess = '/payment/success',
  paymentFailed = '/payment/failed'
}

export enum MomentUnit {
  day = 'day',
  days = 'days',
}

export const InvalidSubscriptionStatuses = {
  INCOMPLETE : 'INCOMPLETE',
  CANCELED : 'CANCELED',
  FAILED : 'FAILED'
}

export const CREATING_USER_SUBSCRIPTION_STATUS = 'CREATING_USER';
