import { makeStyles } from '@material-ui/core/styles';

const useHeaderOptionsStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingLeft: theme.spacing(2),
    whiteSpace: 'nowrap',
    '& button': {
      width: 50,
      height: 50,
      '& .MuiSvgIcon-root': {
        fontSize: 30
      }
    },
    '& .profile-button': {
      marginLeft: theme.spacing(1),
      '& img': {
        borderColor: theme.palette.primary.main
      }
    }
  },
  profileImage: {
    border: '2px solid white',
    borderRadius: 5,
    width: 40,
    height: 40
  },
  profilePopover: {
    '& .profile': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
      '& > *': {
        marginRight: theme.spacing(1)
      },
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.background.default,
        borderRadius: 5
      }
    },
    '& .manage-profiles': {
      margin: theme.spacing(1, 0, 3, 0)
    }
  }
}));

export default useHeaderOptionsStyles;
