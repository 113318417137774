import React, { useEffect } from 'react';
import { Box, Button, Container, Grid, IconButton, Typography } from '@material-ui/core';
import { getDamImage } from '../../helpers/AppHelpers';
import useProfileSelectorStyles from './ProfileSelector.styles';
import useStores from '../../hooks/useStores';
import Loading from '../loading/Loading';
import { observer } from 'mobx-react';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Profile } from '../../domain/Profile';
import ProfileHeader from '../profile_header/ProfileHeader';
import { Page } from '../../constants';
import clsx from 'clsx';

const ProfileSelector = observer(() => {

  const classes = useProfileSelectorStyles();
  const { profileStore } = useStores();
  const history = useHistory();

  useEffect(() => {
    profileStore.loadProfiles().then();
  }, []);

  const editProfile = (profile?: Profile) => {
    profileStore.setEditProfile(profile);
    history.push(Page.editProfile);
  };

  const selectProfile = (profile: Profile) => {
    profileStore.setSelectedProfile(profile);
    history.push(Page.home);
  }

  return (
    <Box className={classes.root}>
      {profileStore.loading && <Loading/>}
      <ProfileHeader titleKey="profile.select"/>
      <Container>
        <Grid container className={classes.profiles} spacing={4}>
          {profileStore.profiles?.map(profile => (
            <Grid item key={profile.id} xs={12} sm={6} md={4} lg={3}>
              <img src={getDamImage(profile.imageId)} alt="profile" onClick={() => selectProfile(profile)}
                className={clsx('icon', {
                  selected: profileStore.selectedProfile?.id === profile.id
                })}/>
              <Typography className="name" variant="h5">
                {profile.name}
                <IconButton className={classes.editButton} onClick={() => editProfile(profile)}>
                  <EditIcon/>
                </IconButton>
              </Typography>
            </Grid>
          ))}
          <Grid item xs={12} sm={6} md={4} lg={3} className={classes.addProfile} onClick={() => editProfile()}>
            <Box className="icon">
              <CameraAltIcon/>
              <Button variant="contained" color="primary" className="add-button">
                <AddIcon/>
              </Button>
            </Box>
            <Typography className="name" variant="h5">
              <Trans i18nKey="profile.add"/>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Typography variant="h4" className="welcome" color="secondary">
        <Trans i18nKey="profile.welcome"/>
      </Typography>
    </Box>
  );

});

export default ProfileSelector;
