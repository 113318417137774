import { Video } from './Video';

export enum ProfileVideoRatingField {
  id = 'id',
  vendorId = 'vendorId',
  rating = 'rating'
}

export interface ProfileVideoRating {
  [ProfileVideoRatingField.id]: number;
  [ProfileVideoRatingField.vendorId]: Video;
  [ProfileVideoRatingField.rating]: number;
}
