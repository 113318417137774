import React from 'react';
import { getMagnoliaImage } from '../../helpers/AppHelpers';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export interface MagnoliaImage {
  '@link': string;
  '@name': string;
}

interface Props {
  image: MagnoliaImage;
  delay?: number;
}

const Image = (props: Props) => {
  const {image, delay} = props;
  return (
    <LazyLoadImage src={ getMagnoliaImage(image['@link']) } alt={image['@name']} effect="blur" delayTime={delay ?? 150}/>
  );
};

export default Image;
