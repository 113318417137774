import { makeStyles } from '@material-ui/core/styles';
import { getUrImage } from '../../helpers/AppHelpers';

const useFullScreenBackgroundStyles = makeStyles(() => ({
  root: {
    zIndex: -1,
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundImage: getUrImage('onboarding-background.jpg'),
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '& .overlay': {
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(0, 0, 0, 0.75)'
    }
  }
}));

export default useFullScreenBackgroundStyles;
