import { makeAutoObservable } from 'mobx';
import { ProfileVideo } from '../domain/ProfileVideo';
import { Video } from '../domain/Video';
import { get, post, remove } from '../services/http.service';
import ProfileStore from './ProfileStore';

class ProfileVideosStore {
  private readonly profileStore: ProfileStore;
  private visible = false;
  private loading;
  private list: ProfileVideo[] = null;

  constructor(profileStore: ProfileStore) {
    makeAutoObservable(this);
    this.profileStore = profileStore;
  }

  isListVisible(): boolean {
    return this.visible;
  }

  showList() {
    this.visible = true;
    this.fetch().then();
  }

  closeList() {
    this.visible = false;
  }

  getList(): ProfileVideo[] {
    return this.list;
  }

  isLoading(): boolean {
    return this.loading;
  }

  private setLoading(value: boolean) {
    this.loading = value;
  }

  private setList(data: ProfileVideo[]) {
    this.list = data;
  }

  async fetch(recent?: boolean): Promise<void> {
    try {
      this.setLoading(true);
      const { selectedProfile } = this.profileStore;
      const { data } = await get<ProfileVideo[]>(`/user-profile/${selectedProfile.id}/videos?recent=${recent ?? false}`);
      this.setList(data);
    } finally {
      this.setLoading(false);
    }
  }

  async saveVideo(v: Video): Promise<boolean> {
    let retVal = true;
    try {
      this.setLoading(true);
      const { selectedProfile } = this.profileStore;
      await post<void, any>(`/user-profile/${selectedProfile.id}/videos/${v.id}`);
    } catch (ex) {
      retVal = false;
    } finally {
      this.setLoading(false);
    }
    return retVal;
  }

  async removeVideo(v: Video): Promise<boolean> {
    let retVal = true;
    try {
      this.setLoading(true);
      const { selectedProfile } = this.profileStore;
      await remove(`/user-profile/${selectedProfile.id}/videos/${v.id}`);
      this.setList(this.list.filter(item => item.video.id !== v.id));
    } catch (ex) {
      retVal = false;
    } finally {
      this.setLoading(false);
    }
    return retVal;
  }
}

export default ProfileVideosStore;
