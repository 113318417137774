import { Box, Fab, Tooltip, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PlayIcon from '@material-ui/icons/PlayArrow';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Video } from '../../../domain/Video';
import useDialogs from '../../../hooks/useDialogs';
import useStores from '../../../hooks/useStores';
import { Rating } from '../../rating/Rating';
import { useVideoDetailsMiniTooltipStyles } from './VideoDetailsMiniTooltip.styles';

export interface VideoDetailsMiniTooltipProps {
  video: Video;
  disabled?: boolean;
  children: JSX.Element;
}

const Component: React.FC<VideoDetailsMiniTooltipProps> = (props: VideoDetailsMiniTooltipProps) => {
  const { video, disabled, children } = props;
  const classes = useVideoDetailsMiniTooltipStyles({ thumbnailUrl: video.thumbnail[4]?.url });
  const { videoStore, profileVideosStore, profileRatingsStore } = useStores();
  const { showSnackbar } = useDialogs();
  const { t } = useTranslation();

  const play = () => {
    videoStore.setActive(video);
    videoStore.setPlaying(true);
  };

  const addToMyList = async () => {
    const result = await profileVideosStore.saveVideo(video);
    result && showSnackbar(t('video.addedToList'));
  };

  const saveRating = async (value: number) => {
    const result = await profileRatingsStore.saveRating(video, value);
    result && showSnackbar(t('video.ratingSaved'));
  };

  const TooltipContent = (): JSX.Element => (
    <Box>
      <Box className={classes.thumbnail} />
      <Box className={classes.buttonsAndRating}>
        <Rating value={profileRatingsStore.getRating(video.id)} onChange={saveRating} />
        <Fab color="primary" size="small" aria-label="play video" component="span" onClick={play}>
          <PlayIcon />
        </Fab>
        <Fab color="primary" size="small" aria-label="add to my list" component="span" onClick={addToMyList}>
          <AddIcon />
        </Fab>
      </Box>
      <Typography className={classes.title} variant="body1">
        {video.title}
      </Typography>
    </Box>
  );

  return (
    <Tooltip
      classes={{ tooltip: classes.tooltipContainer }}
      title={<TooltipContent />}
      interactive={true}
      enterDelay={700}
      enterNextDelay={700}
      disableHoverListener={!isNil(disabled) ? disabled : false}
      disableTouchListener={true}
      disableFocusListener={true}
      PopperProps={{
        popperOptions: {
          modifiers: {
            offset: {
              enabled: true,
              offset: '0px, -100%'
            }
          }
        }
      }}
    >
      {children}
    </Tooltip>
  );
};

export const VideoDetailsMiniTooltip = observer(Component);
