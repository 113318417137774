import { Box, Fab, Icon } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import useStores from '../../hooks/useStores';
import { useVideoPlayerStyles } from './VideoPlayer.styles';
import { observer } from 'mobx-react';
import Vimeo from '@u-wave/react-vimeo';
import Loading from '../loading/Loading';
import clsx from 'clsx';

const VENDOR_PLAYER_ID = 'careflix-player';

const Component: React.FC = () => {
  const classes = useVideoPlayerStyles();
  const { videoStore } = useStores();
  const video = videoStore.getActive();
  const showMe = videoStore.isPlayerVisible();
  const [ playerReady, setPlayerReady ] = useState<boolean>(false);
  const [ playerMinified, setPlayerMinified ] = useState<boolean>(false);

  const onClosePlayer = (): void => {
    setPlayerReady(false);
    setPlayerMinified(false);
    videoStore.setActive(null);
  };

  const toggleMinify = ():void => {
    setPlayerMinified(!playerMinified);
  }

  const onPlayerReady = (): void => {
    setPlayerReady(true);
  };

  return (
    <>
      {!!showMe &&
        (
          <>
            <div className={clsx({[classes.overlay]: !playerMinified})} />
            {!playerReady && <Loading />}
            <Box className={clsx(classes.mainContainer, { [classes.minifiedContainer]: playerMinified })}>
              <Fab className={classes.minifyButton} color='primary' size='small' aria-label='close'
                   onClick={toggleMinify}>
                <Icon>
                  {!playerMinified ? 'close_fullscreen' : 'aspect_ratio'}
                </Icon>
              </Fab>
              <Fab className={classes.closeButton} color='primary' size='small' aria-label='close'
                   onClick={onClosePlayer}>
                <CloseIcon />
              </Fab>
              <Vimeo
                id={VENDOR_PLAYER_ID}
                video={video.videoUrl}
                autoplay
                responsive={true}
                onReady={onPlayerReady}
                width='100%'
                height='100%'
              />
            </Box>
          </>
        )
      }
    </>
  );
};

export const VideoPlayer = observer(Component);
