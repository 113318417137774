import React from 'react';
import Image, { MagnoliaImage } from '../../image/Image';

interface Props {
    image: MagnoliaImage,
    link: string
}

const SocialLinkItem = (props: Props) => {

    const { image, link } = props;

    return (
        <a className="social-icon" href={link} target="_blank" rel="noreferrer">
            <Image image={image} />
        </a>
    );

};

export default SocialLinkItem;