import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Chip,
  Container,
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography
} from '@material-ui/core';
import useStores from '../../hooks/useStores';
import Loading from '../loading/Loading';
import { observer } from 'mobx-react';
import ProfileHeader from '../profile_header/ProfileHeader';
import useProfileEditorStyles from './ProfileEditor.styles';
import { getDamImage } from '../../helpers/AppHelpers';
import { Trans, useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import EditIcon from '@material-ui/icons/Edit';
import PersonIcon from '@material-ui/icons/Person';
import CloseIcon from '@material-ui/icons/Close';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { DamImage } from '../../domain/DamImage';
import { Profile, ProfileAgeConfig, ProfileField } from '../../domain/Profile';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Page } from '../../constants';
import { groupBy, keyBy, last, map, orderBy, values, without } from 'lodash';
import { toJS } from 'mobx';
import { ProfileInterestField } from '../../domain/ProfileInterest';
import i18next from 'i18next';

const MaxInterests = 5;
const SelectAllPrefix = 'select-all-';

const ProfileEditor = observer(() => {

  const classes = useProfileEditorStyles();
  const { profileStore, uiStore } = useStores();
  const { t } = useTranslation();
  const [openImageSelector, setOpenImageSelector] = useState(false);
  const profile = profileStore.editProfile ?? {} as Profile;
  const required = 'global.required_field';
  const { register, errors, control, handleSubmit, setValue, trigger, watch } = useForm({ mode: 'onChange' });
  const history = useHistory();
  const interestsList = toJS(uiStore.profileInterests);
  const interestGroups = groupBy(interestsList, ProfileInterestField.category);
  const interestMap = keyBy(interestsList, ProfileInterestField.id);
  const interests = watch(ProfileField.interests, profile.interests);
  const imageId = watch(ProfileField.imageId, profile.imageId);
  const [ editMode, setEditMode ] = useState(false);


  useEffect(() => {
    Promise.all([
      profileStore.loadProfileImages(),
      uiStore.loadProfileInterests()
    ]).then(() => {
      setEditMode(Object.keys(profile).length > 0)
    });
  }, []);

  const selectImage = (image: DamImage) => {
    setValue(ProfileField.imageId, image.id);
    trigger(ProfileField.imageId).then();
    setOpenImageSelector(false);
  };

  const onSubmit = (data: Profile) => {
    profileStore.saveProfile(data)
      .then(() => {
        navigateToSelectProfile();
        i18next.changeLanguage(data.lang);
      });
  };

  const navigateToSelectProfile = () => history.push(Page.selectProfile);

  const handleChipDelete = (id) => {
    const index = interests.indexOf(id);
    if (index !== -1) {
      let ids = [...interests];
      ids.splice(index,1);
      setValue(ProfileField.interests, ids);
      trigger(ProfileField.interests).then();
    }
  };

  const handleMultiSelectChange = (event) => {
    const value = last(event.target.value) as string;
    let ids = [...interests];

    if (value?.startsWith(SelectAllPrefix)) {
      let groupName = value.replace(SelectAllPrefix, '');
      let groupIds = map(interestGroups[groupName], item => item.id);
      let allSelected = !groupIds.find(id => ids.indexOf(id) < 0);

      ids = without(ids, ...groupIds);
      if (!allSelected) {
        ids = [...ids, ...groupIds];
      }
    } else {
      ids = event.target.value;
    }
    setValue(ProfileField.interests, ids);
    trigger(ProfileField.interests).then();
  };

  const handleProfileDelete = () => {
    profileStore.removeProfile(profile.id).then(() => navigateToSelectProfile());
  };

  return (
    <Box className={classes.root}>
      {profileStore.loading && <Loading/>}
      <ProfileHeader titleKey="profile.edit"/>
      <Container className="content">
        <Grid container>
          <Grid item xs={12} sm={5}>
            <Box className="image-box">
              {imageId ? (
                <img src={getDamImage(imageId)} alt="profile" className="image"/>
              ) : (
                <PersonIcon className="image profile-icon"/>
              )}
              <Button variant="contained" color="primary" className="edit-button" onClick={() => setOpenImageSelector(true)}>
                <EditIcon/>
              </Button>
            </Box>
            {!!errors.imageId && (
              <Box className="image-alert">
                <Typography color="error">
                  <Trans i18nKey="profile_edit.select_image"/>
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid xs={12} sm={6} md={5}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <TextField
                className="hidden"
                name={ProfileField.imageId}
                defaultValue={profile?.imageId}
                error={!!errors.imageId}
                inputRef={register({ required })}
              />

              <TextField
                className="hidden"
                name={ProfileField.id}
                defaultValue={profile?.id}
                inputRef={register}
              />

              <TextField
                fullWidth
                name={ProfileField.name}
                label={t('profile_edit.name')}
                defaultValue={profile?.name}
                error={!!errors.name}
                helperText={t(errors.name?.message)}
                inputRef={register({ required })}
              />

              <FormControl fullWidth error={!!errors.lang}>
                <InputLabel>
                  <Trans i18nKey="profile_edit.language"/>
                </InputLabel>
                <Controller
                  name={ProfileField.lang}
                  defaultValue={profile?.lang}
                  rules={{ required }}
                  control={control}
                  as={
                    <Select>
                      <MenuItem value="">
                        &nbsp;
                      </MenuItem>
                      <MenuItem value="es">
                        Español
                      </MenuItem>
                      <MenuItem value="en">
                        English
                      </MenuItem>
                    </Select>
                  }
                />
                <FormHelperText>{t(errors.lang?.message)}</FormHelperText>
              </FormControl>

              <FormControl fullWidth error={!!errors.ageConfig}>
                <InputLabel>
                  <Trans i18nKey="profile_edit.age"/>
                </InputLabel>
                <Controller
                  name={ProfileField.ageConfig}
                  defaultValue={profile?.ageConfig}
                  rules={{ required }}
                  control={control}
                  as={
                    <Select>
                      <MenuItem value="">
                        &nbsp;
                      </MenuItem>
                      {values(ProfileAgeConfig).map(age => (
                        <MenuItem key={age} value={age}>
                        <Trans i18nKey={`profile_edit.age.${age}`}/>
                      </MenuItem>
                      ))}
                    </Select>
                  }
                />
                <FormHelperText>{t(errors.ageConfig?.message)}</FormHelperText>
              </FormControl>

              <FormControl fullWidth error={!!errors.interests}>
                <InputLabel>
                  <Trans i18nKey="profile_edit.interests"/>
                </InputLabel>
                <Controller
                  name={ProfileField.interests}
                  multiple
                  defaultValue={interests ?? []}
                  control={control}
                  rules={{
                    validate: (value) => (
                      value.length > MaxInterests ?
                        'profile_edit.interests.max_size' :
                        value.length || required
                    )
                  }}
                  render={({ value }) =>
                    <Select
                      multiple
                      value={value}
                      onChange={handleMultiSelectChange}
                      input={<Input/>}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left'
                        }
                      }}
                      renderValue={() => (
                        <Box className={classes.interestsRender}>
                          {orderBy(
                            interests.map(id => interestMap[id])
                              .filter(value => !!value)
                              .map(interest => (
                                <Chip
                                  key={interest.id}
                                  label={interest.name}
                                  onMouseDown={(event) => {event.stopPropagation();}}
                                  onDelete={() => {handleChipDelete(interest.id)}}
                                  color="secondary"
                                />
                              )),
                            ProfileField.name
                          )}
                        </Box>
                      )}
                    >
                      {map(interestGroups, (groupInterests, key) => [(
                        <MenuItem key={key} value={`${SelectAllPrefix}${key}`}>
                          <ListItemText primary={key} secondary={t('global.select_all_none')}/>
                        </MenuItem>
                      ),
                        orderBy(groupInterests, ProfileField.name).map((interest) => (
                          <MenuItem key={interest.id} value={interest.id}>
                            <Checkbox checked={interests?.indexOf(interest.id) >= 0}/>
                            <ListItemText primary={interest.name}/>
                          </MenuItem>
                        ))
                      ])}
                    </Select>
                  }
                />
                <FormHelperText>{t('profile_edit.interests.max_size', { max: MaxInterests })}</FormHelperText>
              </FormControl>

              <Box className="buttons">
                <Button type="submit" color="primary" variant="contained">
                  <Trans i18nKey="global.save"/>
                </Button>
                <Button type="button" color="primary" variant="outlined" onClick={navigateToSelectProfile}>
                  <Trans i18nKey="global.cancel"/>
                </Button>
                {!!editMode &&
                  <Button type="button" variant="contained" className={classes.deleteButton} onClick={handleProfileDelete}>
                    <Trans i18nKey="global.remove"/>
                  </Button>
                }
              </Box>
            </form>
          </Grid>
        </Grid>
      </Container>

      <Dialog open={openImageSelector} fullScreen={true}>
        <AppBar position="sticky" color="default">
          <Toolbar className={classes.imageSelectorToolbar}>
            <Box width={1} flexGrow={1}>
              <Typography variant="h5">
                <Trans i18nKey="profile_edit.select_image"/>
              </Typography>
            </Box>
            <IconButton onClick={() => setOpenImageSelector(false)}>
              <CloseIcon/>
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.imageSelector}>
          <Grid container spacing={2}>
            {profileStore.profileImages?.map(image => (
              <Grid item key={image.id} xs={12} sm={4} md={3} lg={2}>
                <LazyLoadImage src={getDamImage(image.id)} alt={image.name} onClick={() => selectImage(image)}
                  className={clsx({
                    selected: image.id === imageId
                  })}/>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );

});

export default ProfileEditor;
