import { makeStyles } from '@material-ui/core/styles';

const useHeaderLinksStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    flexGrow: 1,
    '& a': {
      flex: 1,
      padding: theme.spacing(0, 1),
      textAlign: 'center',
      textDecoration: 'none',
      color: 'white',
      whiteSpace: 'nowrap',
      fontWeight: 600,
      fontSize: 16,
      '&::after': {
        content:'""',
        marginLeft: theme.spacing(.4),
        lineHeight: '10px',
        borderRight: '1px solid white',
      },
      '&:hover:not(.selected)': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&::after': {
          borderColor: theme.palette.primary.main
        }
      },
      '&.selected': {
        color: theme.palette.secondary.main,
        '&::after': {
          borderColor: theme.palette.secondary.main
        }
      }
    }
  }
}));

export default useHeaderLinksStyles;
