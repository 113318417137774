import React from 'react';
import { Box, Link, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

interface Props {
  text: string,
  link: string
}

const OnboardingLinkItem = (props: Props) => {

  const { text, link = '#' } = props;


  return (
    <Link href={link} target="_blank" className="item">
      <Box flexGrow={ 1 }>
        <Typography variant="h6" className="text">{ text }</Typography>
      </Box>
      <NavigateNextIcon color="secondary" fontSize="large"/>
    </Link>
  );
};

export default OnboardingLinkItem;
