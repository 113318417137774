import Basic from './pages/basic/Basic';
import VideoProspect from './pages/videoProspect/VideoProspect';
import OnboardingIntro from './components/onboarding_intro/OnboardingIntro';
import OnboardingBanner from './components/onboarding_banner/OnboardingBanner';
import OnboardingFaqs from './components/onboarding_faqs/OnboardingFaqs';
import RichText from './components/rich_text/RichText';
import Image from './components/image/Image';
import OnboardingFaqItem from './components/onboarding_faqs/OnboardingFaqItem';
import OnboardingLinkItem from './components/onboarding_links/OnboardingLinkItem';
import OnboardingLinks from './components/onboarding_links/OnboardingLinks';
import Onboarding from './pages/onboarding/Onboarding';
import MainCarousel from './components/main_carousel/MainCarousel';
import MainCarouselItem from './components/main_carousel/MainCarouselItem';
import TagsVideosCarousel from './components/tags_videos_carousel/TagsVideosCarousel';
import Signup from './pages/signup/Signup';
import SignupInfoItem from './components/signup_info_item/SignupInfoItem';
import VideoProspects from './components/video_prospects/VideoProspects';
import VideoProspectsCategory from './components/video_prospects/VideoProspectsCategory';
import Footer from './components/footer/Footer';
import SocialLinks from './components/footer/SocialLinks/SocialLinks';
import FooterLinks from './components/footer/FooterLinks/FooterLinks';
import SocialLinkItem from './components/footer/SocialLinks/SocialLinkItem';
import FooterLinkItem from './components/footer/FooterLinks/FooterLinkItem';
import FooterPdfItem from './components/footer/FooterLinks/FooterPdfItem';
import FaqPage from './pages/faqPage/FaqPage';

const config = {
  'componentMappings': {
    // Page Components
    'careflix-web-lm:pages/Basic': Basic,
    'careflix-web-lm:pages/VideoProspect': VideoProspect,
    'careflix-web-lm:pages/Onboarding': Onboarding,
    'careflix-web-lm:pages/Signup': Signup,
    'careflix-web-lm:pages/FaqPage': FaqPage,

    // Components
    'careflix-web-lm:components/OnboardingIntro': OnboardingIntro,
    'careflix-web-lm:components/OnboardingBanner': OnboardingBanner,
    'careflix-web-lm:components/OnboardingFaqs': OnboardingFaqs,
    'careflix-web-lm:components/RichText': RichText,
    'careflix-web-lm:components/Image': Image,
    'careflix-web-lm:components/OnboardingFaqItem': OnboardingFaqItem,
    'careflix-web-lm:components/OnboardingLinks': OnboardingLinks,
    'careflix-web-lm:components/OnboardingLinkItem': OnboardingLinkItem,
    'careflix-web-lm:components/MainCarousel': MainCarousel,
    'careflix-web-lm:components/MainCarouselItem': MainCarouselItem,
    'careflix-web-lm:components/TagsVideosCarousel': TagsVideosCarousel,
    'careflix-web-lm:components/SignupInfoItem': SignupInfoItem,
    'careflix-web-lm:components/VideoProspects': VideoProspects,
    'careflix-web-lm:components/VideoProspectsCategory': VideoProspectsCategory,
    'careflix-web-lm:components/Footer': Footer,
    'careflix-web-lm:components/SocialLinks': SocialLinks,
    'careflix-web-lm:components/FooterLinks': FooterLinks,
    'careflix-web-lm:components/SocialLinkItem': SocialLinkItem,
    'careflix-web-lm:components/FooterLinkItem': FooterLinkItem,
    'careflix-web-lm:components/FooterPdfItem': FooterPdfItem
  }
};

export default config;
