import { Box, Button, Chip, Divider, Fab, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import TagIcon from '@material-ui/icons/LocalOfferOutlined';
import PlayIcon from '@material-ui/icons/PlayArrow';
import { Rating } from '../rating/Rating';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useDialogs from '../../hooks/useDialogs';
import useStores from '../../hooks/useStores';
import { getContainerBackgroundStyle, useVideoDetailsDialogStyles } from './VideoDetailsDialog.styles';

const Component: React.FC = () => {
  const classes = useVideoDetailsDialogStyles();
  const { videoStore, profileVideosStore, profileRatingsStore } = useStores();
  const { showSnackbar } = useDialogs();
  const { t } = useTranslation();
  const video = videoStore.getActive();
  const showme = videoStore.isDetailsVisible();
  const showAddToMyListButton = !profileVideosStore.isListVisible();

  const handleClose = (): void => {
    videoStore.setActive(null);
  };

  const handlePlay = () => {
    videoStore.setPlaying(true);
  };

  const handleAddToMyList = async () => {
    const result = await profileVideosStore.saveVideo(video);
    result && showSnackbar(t('video.addedToList'));
  };

  const handleRemoveFromMyList = async () => {
    const result = await profileVideosStore.removeVideo(video);
    if (result) {
      showSnackbar(t('video.removedToList'));
      videoStore.setActive(null);
    }
  };

  const handleSaveRating = async (value: number) => {
    const result = await profileRatingsStore.saveRating(video, value);
    result && showSnackbar(t('video.ratingSaved'));
  };

  return (
    showme && (
      <Dialog
        className={classes.root}
        fullWidth={true}
        maxWidth="md"
        open={showme}
        onClose={handleClose}
        aria-labelledby="video-details-dialog"
      >
        <DialogContent className={classes.container} style={getContainerBackgroundStyle(video)}>
          <Typography className={classes.title} variant="h4" color="secondary">
            {video.title}
          </Typography>
          <Rating value={profileRatingsStore.getRating(video.id)} onChange={handleSaveRating} />
          <Box className={classes.buttonsAndTags}>
            {showAddToMyListButton && (
              <Fab size="medium" color="primary" aria-label="add to my list" component="span" onClick={handleAddToMyList}>
                <AddIcon />
              </Fab>
            )}
            {!showAddToMyListButton && (
              <Fab size="medium" color="primary" aria-label="remove from my list" component="span" onClick={handleRemoveFromMyList}>
                <RemoveIcon />
              </Fab>
            )}
            <Divider orientation="vertical" flexItem />
            <Box className="tags">
              {video.tags?.filter(tag => tag.trim().length).map((tag, i) => (
                <Chip key={i} color="primary" label={tag} icon={<TagIcon />} />
              ))}
            </Box>
          </Box>
          <Button className={classes.playButton} variant="contained" color="secondary" onClick={handlePlay}>
            <PlayIcon /> {t('video.play')}
          </Button>
          <Typography className={classes.description} variant="caption">
            {video.description}
          </Typography>
        </DialogContent>
      </Dialog>
    )
  );
};

export const VideoDetailsDialog = observer(Component);
