import React, { useState } from 'react';
import SnackbarMessage from '../components/snackbar_message/SnackbarMessage';
import ConfirmDialog from '../components/confirm_dialog/ConfirmDialog';
import noop from 'lodash';

interface DialogContextState {
  snackbarMessage?: string;
  snackbarError?: boolean;
  confirmMessage?: string;
  onCloseConfirm?: (result: boolean) => void;
}

export const DialogsContext = React.createContext({
  state: {},
  setState: (state: DialogContextState) => {
    noop(state)
  }
});

export const DialogsContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const [state, setState] = useState<DialogContextState>({});

  const closeSnackbar = () => setState({ ...state, snackbarMessage: undefined });

  const closeConfirm = (confirmResult: boolean) => {
    setState({ ...state, confirmMessage: undefined });
    if (state?.onCloseConfirm) {
      state.onCloseConfirm(confirmResult);
    }
  };

  return (
    <DialogsContext.Provider value={{ state, setState }}>
      {children}
      <SnackbarMessage message={state?.snackbarMessage} error={state?.snackbarError} onClose={closeSnackbar}/>
      <ConfirmDialog message={state?.confirmMessage} onClose={(result) => closeConfirm(result)}/>
    </DialogsContext.Provider>
  );
};
