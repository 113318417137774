export const PUBLIC_URL = process.env.PUBLIC_URL ?? '';
export const MGNL_APP_BASE = process.env.REACT_APP_MGNL_APP_BASE ?? '';
export const MGNL_HOST = process.env.REACT_APP_MGNL_HOST ?? '';
export const MGNL_BASE_AUTHOR = process.env.REACT_APP_MGNL_BASE_AUTHOR ?? '';
export const MGNL_BASE_PUBLIC = process.env.REACT_APP_MGNL_BASE_PUBLIC ?? '';

export const MGNL_API_PAGES = '/.rest/delivery/pages/v1';
export const MGNL_API_TEMPLATES = '/.rest/templateDefinition/v1';
export const MGNL_API_NAV = '/.rest/delivery/pagenav/v1';
export const MGNL_API_I18N = '/.rest/delivery/i18n/@nodes'
export const MGNL_API_IMAGES = '/.rest/delivery/dam';
export const MGNL_API_PROFILE_INTERESTS = '/.rest/delivery/profile-interest/@nodes';
export const MGNL_API_LIVE_EVENTS = '/.rest/delivery/live-event';
export const MGNL_API_FOOTER_PATH = MGNL_APP_BASE + '/footer';

