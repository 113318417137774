import { makeStyles } from '@material-ui/core/styles';

const useOnboardingIntroStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100vw',
    minHeight: '100vh',
    textAlign: 'center',
    padding: theme.spacing(2),
    '& p': {
      margin: 0
    },
    '& .MuiButtonBase-root': {
      height: 60,
      fontSize: '1.2rem'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '2rem'
    },
    '& h1': {
      fontWeight: 600,
      paddingTop: theme.spacing(16)
    },
    '& h3': {
      marginTop: theme.spacing(4)
    },
    '& .logon-button': {
      marginTop: theme.spacing(6)
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiTypography-h1': {
        fontSize: 44,
      },
      '& button': {
        maxHeight: 40,
        '& .MuiButton-label': {
          fontSize: 15,
        }
      }
    }
  }
}));

export default useOnboardingIntroStyles;
