import React from 'react';
import { EditableArea } from '@magnolia/react-editor';
import useFooterStyles from '../Footer.styles';

interface Props {
    sociallinkitem: object,
    metadata: object
}

const SocialLinksArea = (props: Props) => {

    const { sociallinkitem, metadata } = props;
    const classes = useFooterStyles();

    return (
        <>
            {sociallinkitem && 
                <EditableArea 
                    content={sociallinkitem} 
                    parentTemplateId={ metadata['mgnl:template'] } 
                    elementType="div"
                    className={classes.fullWidth}
            />}
        </>
    );

};

export default SocialLinksArea;