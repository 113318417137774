import React, { useEffect } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { map } from 'lodash';
import { propertiesMap } from '../choose_plan/ChoosePlan';
import CheckIcon from '@material-ui/icons/Check';
import useChoosePlanStyles from '../choose_plan/ChoosePlan.styles';
import useStores from '../../hooks/useStores';
import { useTranslation } from 'react-i18next';

interface Props {
  openDialog: boolean;
  setOpenDialog: any;
}

const PlansDialog = (props: Props) => {
  const { openDialog, setOpenDialog } = props;
  const planClasses = useChoosePlanStyles();
  const { planStore } = useStores();
  const { t } = useTranslation();

  useEffect(() => {
    planStore.loadPlans().then();
  }, []);

  const translatedProperties = {
    'choose_plan.price': t('choose_plan.price'),
    'choose_plan.concurrent_devices': t('choose_plan.concurrent_devices'),
    'choose_plan.free_weeks': t('choose_plan.free_weeks'),
    'choose_plan.health_assistant': t('choose_plan.health_assistant'),
    'choose_plan.medical_consult': t('choose_plan.medical_consult'),
    'choose_plan.cancel_any_time': t('choose_plan.cancel_any_time'),
    'choose_plan.videoprospects': t('choose_plan.videoprospects'),
    'choose_plan.live_events': t('choose_plan.live_events'),
  };

  return (
    <Dialog maxWidth="xl" open={openDialog} onClose={() => setOpenDialog(false)}>
      <DialogTitle disableTypography={true}>
        <Typography variant="h5" color="primary">
          {t('edit_account.our_plans')}
        </Typography>
      </DialogTitle>
      <DialogContent className={planClasses.root} style={{minHeight: '400px', overflowX: 'auto'}}>
        <Typography component="div" className="content">
          <Grid container>
            <Grid container className="names">
              <Grid item md={3} lg={4} className="label">
              </Grid>
              {planStore.plans?.map(plan =>
                <Grid item xs={4} md={3} lg={2} key={plan.id} className="item">
                  <Box className="box">
                    {plan.name}
                  </Box>
                </Grid>
              )}
            </Grid>
            <Box className="values">
              {map(propertiesMap,(field, key)=>
                <Grid key={key} container>
                  <Grid item md={3} lg={4} className="label">
                    <Box textAlign="right" width={1}>
                      {translatedProperties[key]}
                    </Box>
                  </Grid>
                  {planStore.plans?.map(plan => {
                      const value = plan[`${field}`];
                      return (
                        <Grid item xs={4} md={3} lg={2} key={plan.id}>
                          <Box className="box">
                            <Box className="label">
                              {translatedProperties[key]}
                            </Box>
                            {[field, value].indexOf(true) >= 0?
                              <CheckIcon/> :
                              value || '-'
                            }
                            {field === 'price' ? ' €' : ''}
                          </Box>
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              )}
            </Box>
          </Grid>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setOpenDialog(false)} color="primary">
          {t('edit_account.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PlansDialog;
