import React, { useEffect } from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import useStores from '../../hooks/useStores';
import { observer } from 'mobx-react';
import Loading from '../loading/Loading';
import useLiveEventsStyles from './LiveEvents.styles';
import { Trans } from 'react-i18next';
import Header from '../header/Header';
import { getImage } from '../../helpers/AppHelpers';
import Carousel from 'react-multi-carousel';
import LiveEventBox from './event_box/LiveEvent';
import LiveEventDialog from './event_dialog/LiveEventDialog';

const responsiveConfig = {
  largeDesktop: {
    breakpoint: { max: 9999, min: 1920 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 1920, min: 960 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 960, min: 500 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 500, min: 0 },
    items: 1
  }
};

const LiveEvents = observer(() => {

  const classes = useLiveEventsStyles();
  const { liveEventStore } = useStores();
  const { loading, todayEvents, futureEvents } = liveEventStore;

  useEffect(() => {
    liveEventStore.loadLiveEvents().then();
  }, []);

  const NoEnventsFound = () => {
    return (
      <Box textAlign="center" pt={4} pb={12}>
        <Typography variant="h4">
            <Trans i18nKey="live_events.no_found"/>
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Header/>
      <LiveEventDialog/>
      <Container className={classes.root}>
        {loading && <Loading/>}

        <Typography variant="h3" color="secondary" className="header" component="div">
          <img src={getImage('live-icon.png')} alt="live icon" className="live-icon"/>
          <Trans i18nKey="live_events.today"/>
        </Typography>

        <Carousel arrows={true} infinite={true} responsive={responsiveConfig} className="carousel" itemClass="event">
          {todayEvents.map(event => (
            <LiveEventBox key={event.id} event={event}/>
          ))}
        </Carousel>

        {!loading && !todayEvents?.length && (
          <NoEnventsFound/>
        )}

        <Typography variant="h5" color="secondary" className="header">
          <Trans i18nKey="live_events.future"/>
        </Typography>

        <Carousel arrows={true} infinite={true} responsive={responsiveConfig} className="carousel" itemClass="event">
          {futureEvents.map(event => (
            <LiveEventBox key={event.id} event={event}/>
          ))}
        </Carousel>

        {!loading && !futureEvents?.length && (
          <NoEnventsFound/>
        )}

      </Container>
    </>
  );

});

export default LiveEvents;
