import React, { useEffect } from 'react';
import { Box, Container, Grid, Paper, Typography } from '@material-ui/core';
import useSuccessPaymentStyles from './SuccessPayment.styles';
import Lottie from 'lottie-react';
import successAnimation from './success.json';
import useStores from '../../hooks/useStores';
import { InvalidSubscriptionStatuses } from '../../constants';
import { useTranslation } from 'react-i18next';
import { getPublicMainPageURL } from '../../helpers/AppHelpers';

const SuccessPayment = () => {
  const { t } = useTranslation();
  const classes = useSuccessPaymentStyles();
  const { userStore } = useStores();
  const { subscriptionStatus } = userStore;
  const options = {
    animationData: successAnimation,
    autoplay: true,
    loop: false
  };
  let interval = null;

  useEffect(() => {
    setTimeout(() => {
      interval = setInterval(() => {
        if (subscriptionStatus === null) {
          userStore.loadSubscriptionStatus().then();
        }
      }, 3000);
    }, 3000);
  }, []);

  useEffect(() => {
    if (!Object.values(InvalidSubscriptionStatuses).includes(subscriptionStatus)) {
      if (interval) {
        clearInterval(interval);
      }
      setTimeout(() => {
        window.location.replace(getPublicMainPageURL());
      }, 2000);
    }
  }, [subscriptionStatus]);

  return (
    <Box pt={16} className={classes.root}>
      <Container>
        <Paper>
          <Box p={4}>
            <Grid container spacing={4} direction="column">
              <Grid item>
                <Lottie {...options} className="checkIcon" />
              </Grid>
              <Grid item>
                <Typography variant="h3" color="primary">
                  {t('payment_success.payment_successful')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{t('payment_success.please_wait')}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{t('payment_success.will_redirect')}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default SuccessPayment;
