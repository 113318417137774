import PlanStore from './PlanStore';
import UserStore from './UserStore';
import ProfileVideosStore from './ProfileVideosStore';
import VideosStore from './VideoStore';
import UiStore from './UiStore';
import ProfileStore from './ProfileStore';
import LiveEventStore from './LiveEventStore';
import PlayTokenStore from './PlayTokenStore';
import ProfileRatingsStore from './ProfileRatingsStore';

export class RootStore {
  planStore: PlanStore;
  userStore: UserStore;
  profileVideosStore: ProfileVideosStore;
  videoStore: VideosStore;
  uiStore: UiStore;
  profileStore: ProfileStore;
  liveEventStore: LiveEventStore;
  playTokenStore: PlayTokenStore;
  profileRatingsStore: ProfileRatingsStore;

  constructor() {
    this.planStore = new PlanStore();
    this.userStore = new UserStore();
    this.videoStore = new VideosStore();
    this.uiStore = new UiStore();
    this.profileStore = new ProfileStore();
    this.profileVideosStore = new ProfileVideosStore(this.profileStore);
    this.profileRatingsStore = new ProfileRatingsStore(this.profileStore);
    this.liveEventStore = new LiveEventStore();
    this.playTokenStore = new PlayTokenStore();
  }
}

const rootStore = new RootStore();

export default rootStore;