import React from 'react';
import { Box } from '@material-ui/core';
import { getImage } from '../../helpers/AppHelpers';
import LogoutButton from '../logout_button/LogoutButton';
import useSimpleHeaderStyles from './SimpleHeader.styles';

const SimpleHeader = () => {

  const classes = useSimpleHeaderStyles();

  return (
    <Box className={classes.root}>
      <Box flexGrow={1}>
        <img src={getImage('logo.png')} alt="logo"/>
      </Box>
      <Box>
        <LogoutButton/>
      </Box>
    </Box>
  );

}

export default SimpleHeader;
