import { makeStyles } from '@material-ui/core/styles';
import { getUrImage } from '../../helpers/AppHelpers';

const useSignupStyles = makeStyles((theme) => {
  return ({
    root: {
      '& .form': {
        background: 'white',
        color: 'black',
        padding: theme.spacing(2),
        backgroundImage: getUrImage('opacity-logo.png'),
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundPosition: 'center',
        [theme.breakpoints.up('sm')]: {
          padding: theme.spacing(4, '10vw')
        },
        '& .title': {
          textAlign: 'center',
          lineHeight: 'normal',
          marginBottom: theme.spacing(2),
          fontWeight: 500
        },
        '& .gender': {
          marginTop: theme.spacing(2)
        },
        '& .subtitle': {
          textAlign: 'center',
          marginBottom: theme.spacing(6)
        },
        '& form > *': {
          marginBottom: theme.spacing(2)
        }
      },
      '& .info': {
        padding: theme.spacing(0, '4vw'),
        position: 'relative',
        minHeight: '100vh',
        '& .items': {
          marginTop: theme.spacing(8),
          '& .mgnlEditorBar': {
            width: '100%'
          }
        },
        '& .footer': {
          position: 'sticky',
          top: '100vh',
          paddingBottom: theme.spacing(1)
        }
      }
    }
  });
});

export default useSignupStyles;
