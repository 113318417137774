import React, { createContext, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import useOnboardingFaqsStyles from './OnboardingFaqs.styles';
import { EditableArea } from '@magnolia/react-editor';
import clsx from 'clsx';

interface Props {
  title: string,
  items: any,
  metadata: any
}

export const OnboardingFaqsContext = createContext({ expanded: '', setExpanded: (id) => {} });

const OnboardingFaqs = (props: Props) => {

  const { title, items, metadata } = props;
  const classes = useOnboardingFaqsStyles();
  const [expanded, setExpanded] = useState('');

  return (
    <OnboardingFaqsContext.Provider value={ { expanded, setExpanded } }>
      <Box className={ clsx(classes.root, 'onboarding-card') }>
        <Typography variant="h2">{ title }</Typography>
        <Box className="questions">
          { items && <EditableArea content={ items } parentTemplateId={ metadata['mgnl:template'] }/> }
        </Box>
      </Box>
    </OnboardingFaqsContext.Provider>
  );

};

export default OnboardingFaqs;
