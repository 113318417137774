import React, { useEffect, useState } from 'react';
import { getAPIPage } from '../../helpers/AppHelpers';
import { MGNL_API_FOOTER_PATH } from '../../constants';
import { EditableArea } from '@magnolia/react-editor';
import { Box } from '@material-ui/core';

const IncludeFooter = () => {
    const [footer, setFooter] = useState<any>();

    useEffect(() => {
        fetch(getAPIPage(MGNL_API_FOOTER_PATH))
            .then(data => data.json())
            .then(setFooter);
    }, []);

    return (
        <Box mt={6}>
            {footer && (
                <EditableArea content={footer} />
            )}
        </Box>
    );
};

export default IncludeFooter;
