import { makeStyles } from '@material-ui/core';

export const useVideoSearchStyles = makeStyles((theme) => ({
  logo: {
    margin: theme.spacing(2, 1, 2, 0),
    width: 48,
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(2, 2, 2, 0),
      width: 72
    }
  },
  textField: {
    '& > *': {
      fontSize: 20,
      [theme.breakpoints.up('md')]: {
        fontSize: 28
      }
    }
  },
  searchPre: {
    [theme.breakpoints.up('md')]: {
      width: 28,
      height: 28
    }
  },
  iconButton: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2)
    }
  },
  icon: {
    [theme.breakpoints.up('md')]: {
      width: 28,
      height: 28
    }
  },
  resultsGrid: {
    padding: theme.spacing(1, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2)
    }
  },
  resultsItem: {
    padding: theme.spacing(1)
  }
}));
