import { makeStyles } from '@material-ui/core';

export const useUserVideosDialogStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      '& .header-title': {
        paddingRight: 50
      },
      '& .switch': {
        marginLeft: 0
      }
    }
  },
  toolbar: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 2),
    }
  },
  logo: {
    margin: theme.spacing(2),
    width: 72,
    [theme.breakpoints.down('xs')]: {
      width: 48,
      margin: theme.spacing(0, 1, 0, -1)
    }
  },
  iconButton: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(0.5),
      marginLeft: theme.spacing(0)
    }
  },
  icon: {
    [theme.breakpoints.up('md')]: {
      width: 28,
      height: 28
    }
  },
  resultsGrid: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2)
    }
  },
  resultsItem: {
    padding: theme.spacing(1)
  }
}));
