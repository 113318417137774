import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Provider } from 'mobx-react';
import PageLoader from './helpers/PageLoader';
import { getAPIBase, getCurrentLanguage, getRouterBasename, inDevelopment } from './helpers/AppHelpers';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import { Box, CssBaseline } from '@material-ui/core';
import Auth0Provider from './auth/AuthProvider';
import AuthWrapper from './auth/AuthWrapper';
import ProtectedRoute from './auth/ProtectedRoute';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { keyBy, mapValues } from 'lodash';
import { Lang, MGNL_API_I18N, Page } from './constants';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment-timezone';
import 'moment/min/locales';
import { DialogsContextProvider } from './providers/DialogProvider';
import ProfileSelector from './components/profile_selector/ProfileSelector';
import ProfileEditor from './components/profile_editor/ProfileEditor';
import LiveEvents from './components/live_events/LiveEvents';
import MomentUtcUtils from './utils/MomentUtcUtils';
import EditAccount from './components/edit_account/EditAccount';
import SuccessPayment from './pages/successPayment/SuccessPayment';
import FailedPayment from './pages/failedPayment/FailedPayment';
import rootStore from './stores/RootStore';

const App = () => {

  const lang = getCurrentLanguage();
  moment.locale(lang);

  i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      backend: {
        loadPath: `${getAPIBase()}${MGNL_API_I18N}?lang={{lng}}`,
        parse: (data) => mapValues(keyBy(JSON.parse(data), obj => obj.name), obj => obj.text)
      },
      fallbackLng: Lang.es,
      lng: lang,
      debug: inDevelopment(),
      keySeparator: false,
      interpolation: {
        escapeValue: false
      },
      react: {
        useSuspense: false,
        wait: true
      }
    });

  return (
    <BrowserRouter basename={getRouterBasename()}>
      <Auth0Provider>
        <ThemeProvider theme={theme}>
          <Provider {...rootStore}>
            <DialogsContextProvider>
              <MuiPickersUtilsProvider utils={MomentUtcUtils} libInstance={moment} locale={lang}>
                <CssBaseline />
                <AuthWrapper>
                  <Box>
                    <Switch>
                      <ProtectedRoute path={Page.selectProfile} component={ProfileSelector} />
                      <ProtectedRoute path={Page.editProfile} component={ProfileEditor} />
                      <ProtectedRoute path={Page.accountEdit} component={EditAccount} />
                      <ProtectedRoute path={Page.liveEvents} component={LiveEvents} />
                      <ProtectedRoute path={Page.paymentSuccess} component={SuccessPayment} />
                      <ProtectedRoute path={Page.paymentFailed} component={FailedPayment} />
                      <ProtectedRoute path={Page.home} component={PageLoader} />
                    </Switch>
                  </Box>
                </AuthWrapper>
              </MuiPickersUtilsProvider>
            </DialogsContextProvider>
          </Provider>
        </ThemeProvider>
      </Auth0Provider>
    </BrowserRouter>
  );
};

export default (App);
