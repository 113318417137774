import React from 'react';
import { EditableArea } from '@magnolia/react-editor';
import { Box } from '@material-ui/core';
import useOnboardingStyles from './Onboarding.styles';
import { clearLocalStorage } from '../../utils/AppUtils';
import { inEditorOrAuthorMode } from '../../helpers/AppHelpers';
import CookieConsent from '../../components/cookie_consent/CookieConsent';


const Onboarding = (props: { main?: object }) => {

  const { main } = props;
  const classes = useOnboardingStyles();

  if (inEditorOrAuthorMode()) {
    clearLocalStorage();
  }

  return (
    <Box className={classes.root}>
      {main && <EditableArea content={main} />}
      <CookieConsent />
    </Box>
  );
};

export default Onboarding;
