import React, { useEffect, useState } from 'react';
import Loading from '../components/loading/Loading';
import useStores from '../hooks/useStores';
import ChoosePlan from '../components/choose_plan/ChoosePlan';
import { observer } from 'mobx-react';
import ProfileSelector from '../components/profile_selector/ProfileSelector';
import { CREATING_USER_SUBSCRIPTION_STATUS, InvalidSubscriptionStatuses, Page } from '../constants';

const CheckRegisteredUser = observer(({ children }) => {
  const { userStore, profileStore } = useStores();
  const { user, loading, currentUserLoaded, subscriptionStatus } = userStore;
  const { isProfileSelectedOrEditing } = profileStore;
  const { href } = window.location;
  const [subscriptionValid, setSubscriptionValid] = useState(false);

  useEffect(() => {
    userStore.loadCurrentUser()
    .then(() => {
      user?.id && userStore.loadSubscriptionStatus();
    });
  }, []);

  useEffect(() => {
    setSubscriptionValid(!Object.values(InvalidSubscriptionStatuses).includes(subscriptionStatus));
  }, [subscriptionStatus]);

  if (!currentUserLoaded || loading) {
    return <Loading />;
  }

  if (!user || !subscriptionValid) {
    return <ChoosePlan />;
  }

  if (href.includes(Page.paymentFailed) || href.includes(Page.paymentSuccess)) {
    return children;
  }

  return !isProfileSelectedOrEditing &&
    subscriptionStatus !== CREATING_USER_SUBSCRIPTION_STATUS &&
    subscriptionValid ? (
    <ProfileSelector />
  ) : (
    children
  );
});

export default CheckRegisteredUser;
