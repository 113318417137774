import { makeStyles } from '@material-ui/core/styles';

const useOnboardingStyles = makeStyles((theme) => ({
  root: {
    '& .onboarding-card': {
      borderTop: `8px solid ${ theme.palette.grey[800] }`
    }
  }
}));

export default useOnboardingStyles;
