import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { inEditor } from '../helpers/AppHelpers';
import { Page } from '../constants';

const ProtectedRoute = (props) => {

  const { component: Component, location, ...rest } = props;
  const { isAuthenticated } = useAuth0();
  const pathname = location.pathname;
  const inOnboarding = pathname === Page.onboarding;

  return (
    <Route {...rest} render={
      props =>
        isAuthenticated || inOnboarding ?
          (isAuthenticated && inOnboarding && !inEditor() ?
              <Redirect to="/"/> :
              <Component {...rest} {...props} />
          ) :
          <Redirect to={{ pathname: Page.onboarding }}/>
    }/>
  )
};

export default ProtectedRoute;
