import React, { useEffect } from 'react';
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import useChoosePlanStyles from './ChoosePlan.styles';
import useStores from '../../hooks/useStores';
import { useAuth0 } from '@auth0/auth0-react';
import { Trans } from 'react-i18next';
import SimpleHeader from '../simple_header/SimpleHeader';
import { observer } from 'mobx-react';
import { map } from 'lodash';
import CheckIcon from '@material-ui/icons/Check';
import { useHistory } from 'react-router-dom';
import Loading from '../loading/Loading';
import { User } from '../../domain/User';
import { CREATING_USER_SUBSCRIPTION_STATUS, Page } from '../../constants';

export const propertiesMap = {
  'choose_plan.price': 'price',
  'choose_plan.concurrent_devices': 'concurrentDevices',
  'choose_plan.free_weeks': 'freeWeeks',
  'choose_plan.health_assistant': 'healthAssistant',
  'choose_plan.medical_consult': 'medicalConsult',
  'choose_plan.cancel_any_time': true,
  'choose_plan.videoprospects': 'videoprospects',
  'choose_plan.live_events': 'liveEvents',
};

const ChoosePlan = () => {

  const classes = useChoosePlanStyles();
  const { planStore, userStore, profileStore } = useStores();
  const { hasContract } = userStore;
  const { user } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    profileStore.resetProfiles();
    if (hasContract) {
      planStore.setSelectedPlanId(0);
      goToSignUp();
    } else {
      planStore.loadPlans();
    }
  }, [ user ]);

  const goToSignUp = () => {
    const { name, email } = user;
    const userProfile = { name, email } as User;
    userStore.setUser(userProfile);
    userStore.setSubscriptionStatus(CREATING_USER_SUBSCRIPTION_STATUS);
    history.push(Page.signup);
  };

  const onNext = async () => {
    return userStore.user ?
      await userStore.createSubscription(planStore.selectedPlanId) :
      goToSignUp();
  };

  return planStore.loading ? <Loading/> : (
    <Box className={ classes.root }>
      <SimpleHeader/>
      <Container>
        <Box textAlign="center">
          <Typography variant="h2" className="title">
            <Trans i18nKey="choose_plan.title"/>
          </Typography>
          <Typography variant="h4" className="subtitle">
            <Trans i18nKey="choose_plan.subtitle"/>
          </Typography>
        </Box>
        <Typography component="div" className="content">
          <Grid container>
            <Grid container className="names">
              <Grid item md={3} lg={4} className="label">
              </Grid>
              {planStore.plans?.map(plan =>
                <Grid item xs={4} md={3} lg={2} key={plan.id} className="item">
                  <Box className="box">
                    {plan.name}
                    {plan.id === planStore.selectedPlanId && (
                      <CheckIcon/>
                    )}
                  </Box>
                </Grid>
              )}
            </Grid>
            <Box className="values">
              {map(propertiesMap, (field, key) =>
                <Grid key={key} container>
                  <Grid item md={3} lg={4} className="label">
                    <Box textAlign="right" width={1}>
                      <Trans i18nKey={key}/>
                    </Box>
                  </Grid>
                  {planStore.plans?.map(plan => {
                      const value = plan[`${field}`];
                      return (
                        <Grid item xs={4} md={3} lg={2} key={plan.id}>
                          <Box className="box">
                            <Box className="label">
                              <Trans i18nKey={key}/>
                            </Box>
                            {[field, value].indexOf(true) >= 0?
                              <CheckIcon/> :
                              value || '-'
                            }
                            {field === 'price' ? ' €' : ''}
                          </Box>
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              )}
              <Grid container>
                <Grid item md={3} lg={4} className="label">
                </Grid>
                {planStore.plans?.map(plan =>
                  <Grid item xs={4} md={3} lg={2} key={plan.id} className="button-box">
                    <Box className="box">
                      <Button variant="contained" color="primary" fullWidth={true}
                        onClick={() => planStore.setSelectedPlanId(plan.id)}
                      >
                        <Trans i18nKey="choose_plan.want_it"/>
                      </Button>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
            <Grid container>
              <Grid item xs={12} lg={10}>
                <Box width={1} textAlign="right">
                  <Button variant="contained" color="secondary" className="next-button"
                    disabled={!planStore.selectedPlanId}
                    onClick={onNext}
                  >
                    <Trans i18nKey="choose_plan.next"/>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Typography>
      </Container>
    </Box>
  );
};

export default observer(ChoosePlan);
