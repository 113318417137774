import React from 'react';
import { Button } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Trans } from 'react-i18next';
import { getCallbackUri } from '../../helpers/AppHelpers';
import { useAuth0 } from '@auth0/auth0-react';
import { clearLocalStorage } from '../../utils/AppUtils';

const LogoutButton = () => {

  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      returnTo: getCallbackUri()
    });
    clearLocalStorage();
  };


  return (
    <Button variant="contained" color="primary"
      fullWidth={true}
      startIcon={<ExitToAppIcon/>}
      onClick={handleLogout}
    >
      <span className="label">
        <Trans i18nKey="global.logout"/>
      </span>
    </Button>
  );
};

export default LogoutButton;
