import { makeStyles, Theme } from '@material-ui/core';

export interface RatingStylesProps {
  color: 'primary' |  'secondary'
}

export const useRatingStyles = makeStyles<Theme, RatingStylesProps>((theme) => ({
  root: {
    color: (props) => theme.palette[props.color].main
  }
}));
