import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { EditableArea } from '@magnolia/react-editor';
import useOnboardingLinksStyles from './OnboardingLinks.styles';
import clsx from 'clsx';

interface Props {
  title: string;
  phone: string;
  items: object;
  metadata: object;
}

const OnboardingLinks = (props: Props) => {

  const { title, phone, items, metadata } = props;
  const classes = useOnboardingLinksStyles();

  return (
    <Box className={ clsx(classes.root, 'onboarding-card') }>
      <Typography variant="h2" className="title">
        { title }
        <Typography component="span" variant="h2" color="primary">{ phone }</Typography>
      </Typography>
      <Box className="items">
        { items && <EditableArea content={ items } parentTemplateId={ metadata['mgnl:template'] }/> }
      </Box>
    </Box>
  );

};

export default OnboardingLinks;
