import { EditableArea } from '@magnolia/react-editor';
import { Box } from '@material-ui/core';
import React from 'react';
import Header from '../../components/header/Header';
import IncludeFooter from '../../components/IncludeFooter/IncludeFooter';

const VideoProspect = (props: { main?: object }) => {

  const { main } = props;

  return (
    <Box>
      <Header/>
      {main && (
        <EditableArea content={main}/>
      )}
      <IncludeFooter/>
    </Box>
  );
};

export default VideoProspect;
