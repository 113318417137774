import React from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { LiveEvent } from '../../../domain/LiveEvent';
import { Trans } from 'react-i18next';
import useLiveEventStyles from './LiveEvent.styles';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import useStores from '../../../hooks/useStores';
import { formatDate, formatTime, formatVideoDuration } from '../../../utils/AppUtils';

interface Props {
  event: LiveEvent
}

const LiveEventBox = (props: Props) => {

  const { event } = props;
  const classes = useLiveEventStyles({ image: event.video.thumbnail[0]?.url });
  const { liveEventStore } = useStores();

  return (
    <LazyLoadComponent delayTime={100}>
      <Paper className={classes.root} onClick={() => liveEventStore.setSelectedEvent(event)}>
        <Box className="title">
          <Typography variant="h6">
            {event.name}
          </Typography>
        </Box>
        <Box className="content">
          <Box className="image">
            <Typography variant="h6" className="date">
              {formatDate(event.date)}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6" color="secondary">
              {event.organiser}
            </Typography>
            <Box mt={2}>
              <Typography variant="h6">
                <Trans i18nKey="live_event.hour" values={{ hour: formatTime(event.date) }}/>
              </Typography>
              <Typography variant="h6">
                <Trans i18nKey="live_event.duration" values={{ value: formatVideoDuration(event.video) }}/>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </LazyLoadComponent>
  );

};

export default LiveEventBox;
