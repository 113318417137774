import React from 'react';
import { Box } from '@material-ui/core';
import useFullScreenBackgroundStyles from './FullScreenBackground.styles';

const FullScreenBackground = () => {

  const classes = useFullScreenBackgroundStyles();

  return (
    <Box className={classes.root}>
      <Box className="overlay"/>
    </Box>
  );

};


export default FullScreenBackground;
