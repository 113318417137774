import React from 'react';
import { Box, Typography } from '@material-ui/core';
import Image, { MagnoliaImage } from '../image/Image';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { observer } from 'mobx-react';
import useStores from '../../hooks/useStores';
import clsx from 'clsx';

interface Props {
  name: string;
  tags: string;
  image: MagnoliaImage;
}

const VideoProspectsCategory = observer((props: Props) => {

  const { name, tags, image } = props;
  const { uiStore } = useStores();

  const handleClick = () => {
    uiStore.setSelectedCategory(name);
    uiStore.setCategoryTags(tags);
  };

  return (
    <Box>
      <Box onClick={handleClick} className={clsx('category', {
        selected: uiStore.selectedCategory === name
      })}>
        <Image image={image}/>
        <Box flexGrow={1}>
          <Typography variant="h6">
            {name}
          </Typography>
        </Box>
        <ChevronRightIcon/>
      </Box>
    </Box>
  );
});

export default VideoProspectsCategory;
