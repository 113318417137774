import { makeStyles } from '@material-ui/core/styles';

const useLiveEventsStyles = makeStyles((theme) => ({
  root: {
    '& > .header': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 600,
      margin: theme.spacing(4, 0)
    },
    '& .live-icon': {
      width: 80,
      marginRight: theme.spacing(2)
    },
    '& .carousel': {
      margin: theme.spacing(0, -2)
    },
    '& .event': {
      padding: theme.spacing(0, 2),
      minHeight: 200
    },
    '& .react-multiple-carousel__arrow--left': {
      left: 30
    },
    '& .react-multiple-carousel__arrow--right': {
      right: 30
    }
  }
}));

export default useLiveEventsStyles;
