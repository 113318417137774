import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '../components/loading/Loading';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { addTokenInterceptor } from '../services/http.service';
import ValidateEmail from '../components/validate_email/ValidateEmail';
import { inEditorOrAuthorMode } from '../helpers/AppHelpers';
import CheckRegisteredUser from './CheckRegisteredUser';


const AuthWrapper = ({ children }) => {

  const { isLoading, error, isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const { pathname } = useLocation();
  const { ready } = useTranslation();
  const [tokenReady, setTokenReady] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently()
        .then(token => {
          addTokenInterceptor(token);
          setTokenReady(true);
        });
    }
  }, [isAuthenticated]);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  if (error) {
    console.log('Auth0 error', error);
  }

  if (isLoading || !ready || isAuthenticated && !tokenReady) {
    return <Loading/>;
  }

  if (!inEditorOrAuthorMode() && isAuthenticated && !user.email_verified) {
    return <ValidateEmail/>;
  }


  return isAuthenticated ? (
    <CheckRegisteredUser>
      {children}
    </CheckRegisteredUser>
  ) : children;
}

export default AuthWrapper;
