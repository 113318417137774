import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { observer } from 'mobx-react';
import useStores from '../../hooks/useStores';
import TagsVideosCarousel from '../tags_videos_carousel/TagsVideosCarousel';
import { LazyLoadComponent, trackWindowScroll } from 'react-lazy-load-image-component';
import { toJS } from 'mobx';

const ProfileInterestCarousels = observer(({ scrollPosition }) => {

  const { profileStore, uiStore } = useStores();
  const interestMap = uiStore.profileInterestMap;

  useEffect(() => {
    uiStore.loadProfileInterests().then();
  }, []);

  return (
    <LazyLoadComponent delayTime={0} scrollPosition={scrollPosition}>
      <Box>
        {toJS(profileStore.selectedProfile)?.interests?.map(id => interestMap[id]).filter(it => !!it).map(interest => (
          <TagsVideosCarousel key={interest.id} title={interest.name} tags={interest.tags}/>
        ))}
      </Box>
    </LazyLoadComponent>
  );

});

export default trackWindowScroll(ProfileInterestCarousels);
