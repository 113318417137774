import { makeStyles } from '@material-ui/core/styles';

const useOnboardingHeaderStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    '& img': {
      height: 120
    },
    '& button': {
      minWidth: 160
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      '& img': {
        height: 80
      },
      '& button': {
        maxHeight: 34,
        minWidth: 100,
        '& .MuiButton-label': {
          fontSize: 13,
        }
      }
    }
  }
}));


export default useOnboardingHeaderStyles;
