import { EditableArea } from '@magnolia/react-editor';
import { Box } from '@material-ui/core';
import React from 'react';
import Header from '../../components/header/Header';
import ProfileInterestCarousels from '../../components/profile_interest_carousels/ProfileInterestCarousel';
import TopRatingVideos from '../../components/top_rating_videos/TopRatingVideos';

const Basic = (props: { main?: object, footer?: object }) => {

  const { main, footer } = props;

  return (
    <Box>
      <Header/>
      {main && (
        <EditableArea content={main}/>
      )}
      <TopRatingVideos />
      <ProfileInterestCarousels/>
      <Box mt={6}>
        {footer && (
          <EditableArea content={footer}/>
        )}
      </Box>
    </Box>
  );
};

export default Basic;
