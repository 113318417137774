import React, { useEffect, useRef } from 'react';
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { EditableArea } from '@magnolia/react-editor';
import useVideoProspectsStyles from './VideoProspects.styles';
import { Trans, useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import useStores from '../../hooks/useStores';
import SearchIcon from '@material-ui/icons/Search';
import TagsVideosCarousel from '../tags_videos_carousel/TagsVideosCarousel';

interface Props {
  featuredTags: string;
  categories: object;
  metadata: object;
}

const featuredResponsive = {
  desktop: {
    breakpoint: { max: 9999, min: 600 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 600, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


const VideoProspects = observer((props: Props) => {

  const classes = useVideoProspectsStyles();
  const { featuredTags, categories, metadata } = props;
  const { uiStore, videoStore } = useStores();
  const { t } = useTranslation();
  const tagsRef = useRef(null);

  useEffect(() => {
    tagsRef?.current?.scrollIntoView();
  }, [tagsRef, uiStore.selectedCategory]);

  return (
    <Container className={classes.root}>
      <Box textAlign="right">
        <Button variant="contained" color="primary"
          startIcon={<SearchIcon/>}
          onClick={() => videoStore.openVideoProspectSearch()}
        >
          <span className="label">
            <Trans i18nKey="global.search"/>
          </span>
        </Button>
      </Box>
      <Grid container>
        <Grid item xs={12} sm={3}>
          <Typography color="primary" variant="h4">
            <Trans i18nKey="global.categories"/>
          </Typography>
          {categories && (
            <Box mt={1}>
              <EditableArea className="categories" content={categories} parentTemplateId={metadata['mgnl:template']}/>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sm={9} ref={tagsRef}>
          <Box className={classes.content}>
            <Box className={classes.tagBox}>
              <Typography color="secondary" variant="h3" className="title">
                {uiStore.selectedCategory || (
                  <Trans i18nKey="global.tags"/>
                )}
              </Typography>
              <Box className="tags">
                {uiStore.categoryTags?.length ?
                  (uiStore.categoryTags?.map((tag, index) =>
                    <Typography key={index} variant="h5" className="tag"
                      onClick={() => videoStore.openVideoProspectSearch(tag)}>
                      {tag}
                    </Typography>
                  )) :
                  <Typography variant="h5">
                    <Trans i18nKey="video_prospects.select_tag"/>
                  </Typography>
                }
              </Box>
            </Box>
            <Box className={classes.featured}>
              <TagsVideosCarousel title={t('video_prospects.featured')} tags={featuredTags} responsive={featuredResponsive}/>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );

});

export default VideoProspects;
