import React from 'react';
import { IconButton, Slide, Snackbar } from '@material-ui/core';
import useSnackbarMessageStyles from './SnackbarMessage.styles';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import RichText from '../rich_text/RichText';

interface SnackbarMessageProps {
  message?: string,
  error?: boolean,
  onClose: () => void
}

const SnackbarMessage = ({ message, error, onClose }: SnackbarMessageProps) => {

  const classes = useSnackbarMessageStyles();

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={!!message}
      autoHideDuration={5000}
      onClose={onClose}
      TransitionComponent={(props) =>
        <Slide {...props} direction="right"/>
      }
      message={
        <RichText text={message ?? ''}/>
      }
      className={clsx({ [classes.error]: error })}
      ClickAwayListenerProps={{
        onClickAway: () => undefined
      }}
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon fontSize="small"/>
        </IconButton>
      }
    />
  );
};

export default SnackbarMessage;
