import { Box, Grid } from '@material-ui/core';
import React from 'react';
import useFooterStyles from './Footer.styles';
import { EditableArea } from '@magnolia/react-editor';

interface Props {
    title: string,
    socialLinks: object,
    footerLinks: object,
    metadata: object
}

const Footer = (props: Props) => {

    const { socialLinks, footerLinks, metadata } = props;
    const classes = useFooterStyles();

    return (
        <Box>
            <Grid
                container
                direction="row"
            >
                <Grid item xs={12}>
                    {socialLinks && <EditableArea content={socialLinks} parentTemplateId={ metadata['mgnl:template'] } />}
                </Grid>
                <Grid item xs={12}>
                    {footerLinks && <EditableArea content={footerLinks} parentTemplateId={ metadata['mgnl:template'] } />}
                </Grid>
            </Grid>
        </Box>
    );

};

export default Footer;
