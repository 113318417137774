import { makeStyles } from '@material-ui/core/styles';

const useTagsVideosCarousel = makeStyles((theme) => ({
  root: {
    minHeight: 180,
    margin: theme.spacing(3, 1, 2, 1),
    '& .react-multiple-carousel__arrow': {
      display: 'none',
      marginBottom: theme.spacing(6)
    },
    '& .react-multiple-carousel__arrow--left': {
      left: 20
    },
    '& .react-multiple-carousel__arrow--right': {
      right: 20
    },
    '&:hover .react-multiple-carousel__arrow': {
      display: 'block'
    }
  },
  title: {
    margin: theme.spacing(1)
  }
}));

export default useTagsVideosCarousel;
