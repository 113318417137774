import { Grid } from '@material-ui/core';
import React from 'react';
import { getPDFUrl } from '../../../helpers/AppHelpers';

export interface MagnoliaImage {
  '@link': string,
  '@name': string
}

interface Props {
  image: MagnoliaImage,
  text: string
}

const FooterPdfItem = (props: Props) => {

  const { image, text } = props;

  return (
    <Grid
      item
      xs={12}
      sm={3}
    >
      <a href={getPDFUrl(image['@link'])}  target="_blank" rel="noreferrer">
        {text}
      </a>
    </Grid>
  );

};

export default FooterPdfItem;
