import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getAPIBase } from '../helpers/AppHelpers';
import { MGNL_API_IMAGES, MGNL_API_LIVE_EVENTS, MGNL_API_PROFILE_INTERESTS } from '../constants';
import { defaultConfig } from './http.service';
import { DamImage } from '../domain/DamImage';
import { ProfileInterest } from '../domain/ProfileInterest';
import { LiveEvent } from '../domain/LiveEvent';
import moment from 'moment';

const magnoliaApi = axios.create({ baseURL: getAPIBase() })

const mapNodes = (data) => {
  return data?.map(node => ({
    id: node['@id'],
    ...node
  }));
};

const transformNodesResponseConfig = {
  transformResponse: [].concat(
    axios.defaults.transformResponse,
    mapNodes
  )
};

const transformResultsResponseConfig = {
  transformResponse: [].concat(
    axios.defaults.transformResponse,
    (data) => mapNodes(data?.results)
  )
};

export const get = async <T>(endPoint: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  return await magnoliaApi.get(endPoint, { ...defaultConfig, ...config });
};

export const getProfileImages = async (): Promise<AxiosResponse<DamImage[]>> => {
  return await get<DamImage[]>(MGNL_API_IMAGES + '/careflix/profile/@nodes', transformNodesResponseConfig);
};

export const getProfileInterests = async (): Promise<AxiosResponse<ProfileInterest[]>> => {
  return await get<ProfileInterest[]>(MGNL_API_PROFILE_INTERESTS, transformNodesResponseConfig);
};

export const getLiveEvents = async (): Promise<AxiosResponse<LiveEvent[]>> => {
  return await get<LiveEvent[]>(MGNL_API_LIVE_EVENTS, {
    ...transformResultsResponseConfig,
    params: {
      orderBy: 'date',
      'date[gte]': moment().format('YYYY-MM-DD')
    }
  });
};
