import { AppBar, Box, Fade, FormControlLabel, Grid, IconButton, Switch, Toolbar, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getImage } from '../../helpers/AppHelpers';
import useStores from '../../hooks/useStores';
import Loading from '../loading/Loading';
import { VideoCard } from '../video_card/VideoCard';
import { useUserVideosDialogStyles } from './UserVideosDialog.styles';

const Transition = (props: TransitionProps & { children?: React.ReactElement }, ref: React.Ref<unknown>) => {
  return <Fade timeout={50} ref={ref} {...props} />;
};
const FadeTransition = React.forwardRef(Transition);

const Component: React.FC = () => {
  const classes = useUserVideosDialogStyles();
  const { profileVideosStore } = useStores();
  const { t } = useTranslation();
  const [recent, setRecent] = useState(true);
  const showMe = profileVideosStore.isListVisible();
  const loading = profileVideosStore.isLoading();
  const userVideos = profileVideosStore.getList();

  const handleClose = () => {
    profileVideosStore.closeList();
  };

  const handleChangeRecent = (value: boolean) => {
    setRecent(value);
    profileVideosStore.fetch(value);
  };

  return (
    <Dialog fullScreen className={classes.root} open={showMe} TransitionComponent={FadeTransition} aria-labelledby="video-search-dialog">
      <AppBar position="sticky" color="default">
        <Toolbar className={classes.toolbar}>
          <img className={classes.logo} src={getImage('cropped-isotipo-careflix-180x180.png')} alt="logo"/>
          <Box flexGrow={1} display="flex" flexDirection="row-reverse" alignItems="center" flexWrap="wrap">
            <IconButton className={classes.iconButton} edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon className={classes.icon}/>
            </IconButton>
            <FormControlLabel
              label={t('myList.filterRecentlyAdded')}
              labelPlacement="start"
              className="switch"
              control={
                <Switch
                  checked={recent}
                  onChange={(_, checked) => handleChangeRecent(checked)}
                  inputProps={{ 'aria-label': 'recent videos' }}
                />
              }
            />
            <Box flexGrow={1} textAlign="center" className="header-title">
              <Typography variant="h4">
                <Trans i18nKey="myList.title"/>
              </Typography>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Grid container className={classes.resultsGrid}>
        {!!userVideos &&
        !!userVideos.length &&
        userVideos.map((userVideo) => (
          <Grid key={userVideo.id} item className={classes.resultsItem} xs={12} sm={6} md={4} lg={3}>
              <VideoCard video={userVideo.video} disableDetailsTooltip={true}/>
            </Grid>
        ))}
        {!!userVideos && !userVideos.length && (
          <Grid item className={classes.resultsItem} sm={12}>
            <Typography variant="body1">
              <Trans i18nKey="myList.empty"/>
            </Typography>
          </Grid>
        )}
        {loading && <Loading/>}
      </Grid>
    </Dialog>
  );
};

export const UserVideosDialog = observer(Component);
