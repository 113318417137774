import { makeStyles } from '@material-ui/core/styles';

const useValidateEmailStyles = makeStyles((theme) => ({
  root: {
    '& .content': {
      marginTop: theme.spacing(6),
      padding: theme.spacing(4, 8),
      opacity: .8,
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2, 4)
      }
    },
    '& .message': {
      textAlign: 'center',
      marginBottom: theme.spacing(4)
    },
    '& .email-sent': {
      fontWeight: 600
    }
  }
}));

export default useValidateEmailStyles;
