import { makeStyles } from '@material-ui/core/styles';

const useCookiesStyles = makeStyles((theme) => ({
    cookiesContainer: {
        fontFamily: theme.typography.fontFamily,
        padding: '24px 48px',
        [theme.breakpoints.up('sm')]: {
            padding: '24px 96px'
        },
        '& h1': {
            color: '#e5097e'
        },
        '& p': {
            marginBottom: '24px'
        },
        '& a': {
            color: '#f18700',
            textDecoration: 'none',
            fontWeight: 'bold',
            fontSize: '18px'
        }
    },
    acceptButton: {
        [theme.breakpoints.up('sm')]: {
            position: 'absolute',
            bottom: '16px',
            right: '136px',
        }
    },
    declineButton: {
        position: 'absolute',
        top: '24px',
        right: '24px',
    },
  cookieTitle: {
      fontWeight: 600
  },
  cookieContent: {
    paddingRight: '2rem'
  },
  [theme.breakpoints.up('sm')]: {
      cookieContent: {
        paddingRight: '0'
      }
  }
}));

export default useCookiesStyles;

export const generalStyle = {
    backgroundColor: 'white',
    alignItems: 'baseline',
    color: 'black',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    left: '0',
    position: 'fixed',
    width: '100%',
    zIndex: 999,
  };

  export const acceptButtonStyle = {
    background: '#f18700',
    border: '0',
    borderRadius: '5px',
    boxShadow: 'none',
    color: 'white',
    cursor: 'pointer',
    padding: '12px',
    fontWeight: 'bolder',
    fontSize: '18px'
  };

  export const declineButtonStyle = {
    background: 'transparent',
    border: '0',
    borderRadius: '0px',
    boxShadow: 'none',
    color: '#aaaaaa',
    cursor: 'pointer',
    flex: '0 0 auto',
    padding: '0',
    margin: '0',
    fontSize: '32px',
    fontWeight: 500
  };
