export enum GenderType {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NO_BINARY = 'NO_BINARY'
}

interface AbstractUser {
  email: string;
  name: string;
  surnames: string;
  insurance: string;
  gender: GenderType;
  country: string;
  province: string;
  dateOfBirth: Date;
  phone: number;
}

export interface User extends AbstractUser {
  id: number;
}

export interface UserRequest extends AbstractUser {
  planId: number;
}

export interface UserContract {
  hasContract: boolean
}