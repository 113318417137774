import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import 'react-multi-carousel/lib/styles.css';
import 'react-lazy-load-image-component/src/effects/blur.css';

const disableFormFill = {
  transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s'
};

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#E5097E'
    },
    secondary: {
      main: '#F18700',
      contrastText: '#fff'
    },
    error: {
      main: '#FF5733',
      contrastText: '#fff'
    },
    background: {
      default: '#000000',
      paper: '#191919'
    }
  },
  typography: {
    fontFamily: 'Montserrat',
    button: {
      textTransform: 'none',
      fontSize: '1rem',
      fontWeight: 600
    }
  },
  overrides: {
    MuiInputBase: {
      root: {
        '& input': {
          '&:-webkit-autofill': disableFormFill,
          '&:-webkit-autofill:focus': disableFormFill,
          '&:-webkit-autofill:hover': disableFormFill
        }
      }
    },
    MuiCssBaseline: {
      '@global': {
        html: {
          scrollBehavior: 'smooth'
        },
        a: {
          cursor: 'pointer'
        }
      }
    }
  }
});

export default responsiveFontSizes(theme);
