import { makeStyles } from '@material-ui/core';

export const useVideoPlayerStyles = makeStyles((theme) => ({
  mainContainer: {
    position: 'fixed',
    top: '5%',
    left: '10%',
    width: '80vw',
    zIndex: 1301,
    [theme.breakpoints.down('xs')]: {
      width: '100vw'
    }
  },
  minifiedContainer: {
    position: 'fixed',
    top: 'auto',
    left: 'auto',
    bottom: 0,
    right: 0,
    width: 360,
    animation: `$minimize 800ms ${theme.transitions.easing.easeOut}`
  },
  closeButton: {
    position: 'absolute',
    top: -20,
    right: 5,
    zIndex: 2
  },
  minifyButton: {
    position: 'absolute',
    top: -20,
    right: 60,
    zIndex: 2
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1300,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.7)'
  },
  '@keyframes minimize': {
    from: {
      width: '80vw'
    },
    to: {
      width: 360
    }
  }
}));
