import React from 'react';
import { Box, Button, Container, Grid, Paper, Typography } from '@material-ui/core';
import useSuccessPaymentStyles from '../successPayment/SuccessPayment.styles';
import Lottie from 'lottie-react';
import failedAnimation from './failed.json';
import { useTranslation } from 'react-i18next';

const FailedPayment = () => {
  const { t } = useTranslation();
  const classes = useSuccessPaymentStyles();
  const options = {
    animationData: failedAnimation,
    autoplay: true,
    loop: false
  };

  const handleClick = () => {
    window.location.replace('/');
  };

  return (
    <Box pt={16} className={classes.root}>
      <Container>
        <Paper>
          <Box p={4}>
            <Grid container spacing={4} direction="column">
              <Grid item>
                <Lottie {...options} className="checkIcon" />
              </Grid>
              <Grid item>
                <Typography variant="h3" color="primary">
                  {t('payment_failed.something_went_wrong')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{t('payment_failed.problem')}</Typography>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={handleClick}>
                  {t('payment_failed.ok')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default FailedPayment;
